import React, { Component } from "react";
import { Card, CardBody, Col, Container, FormGroup, Input, Row } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import TextEditor from "../../../components/TextEditor";
import { DefaultSelect } from "../../../components/Forms/Inputs/Select";
import { DefaultButton } from "../../../components/Buttons/default";
import TagsInput from 'react-tagsinput'
import { AiOutlineCamera, AiOutlineLoading3Quarters } from "react-icons/ai";
import 'react-tagsinput/react-tagsinput.css'
import './styles.css';
import { API_LINK } from "../../../utils/constants";
import { withRouter } from "../../../components/Router/withRouter";
import { BottomLeftAlert } from "../../../components/Alerts";

class BlogAddPostPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      languagesData: null,
      languagesLoading: true,

      addLoading: false,

      title: '',
      image: null,
      content: '',
      keywords: [],
      lang: '',

      alertText: null
    }
  }

  componentDidMount() {
    this.getLanguages();
  }

  getLanguages() {
    fetch(API_LINK+'/languages', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        languagesData: json.languages,
        languagesLoading: false
      });
    })
  }

  storePost() {
    this.setState({addLoading: true});

    const formData = new FormData();
    formData.append('image', this.state.image);
    formData.append('title', this.state.title);
    formData.append('content', this.state.content);
    formData.append('lang', this.state.lang);
    formData.append('keywords', this.state.keywords);

    fetch(API_LINK+'/blog/post/store', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: formData
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.props.navigate('/dashboard/blog/posts');
      } else {
        this.setState({
          alertText: json.message,
          addLoading: false
        });
      }
    })
  }

  handleChange(event) {
    const { name, value, type } = event.target;

    this.setState((prevState) => ({
      ...prevState,
      [name]: type === 'file' ? event.target.files[0] : value
    }));
  }

  render() {
    const s = this.state;

    return(
      <Container className="pb-5">
        <AdminBodyTitle>إضافة مقال</AdminBodyTitle>

        {s.languagesLoading ?
          <div className="text-center">
            <AiOutlineLoading3Quarters className="animation-spin h2" />
          </div>
        :
          <Row>
            <Col md="8" className="offset-md-2">
              <Card>
                <CardBody>
                  <FormGroup>
                    <DefaultSelect
                      value={s.lang}
                      name={'lang'}
                      onChange={(event) => this.handleChange(event)}
                    >
                      <option value="">اختر اللغة</option>
                      {s.languagesData && s.languagesData.length > 0 &&
                        s.languagesData.map((data, key) => {
                          return(
                            <option value={data.short} key={key}>{data.name}</option>
                          );
                        })
                      }
                    </DefaultSelect>
                  </FormGroup>
                  <FormGroup>
                    <DefaultInput 
                      placeholder={'عنوان المقال'} 
                      name={'title'}
                      onChange={(event) => this.handleChange(event)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <div className="blogpost-image-selection bg-light cursor-pointer" onClick={() => document.getElementById('imageInput').click()}>
                      {s.image ?
                        <img src={URL.createObjectURL(s.image)} width={'100%'} />
                      :
                        <div className="text-center py-5">
                          <AiOutlineCamera size={160} />
                        </div>
                      }
                      <Input 
                        type="file" 
                        name="image" 
                        onChange={(event) => this.handleChange(event)} 
                        className="d-none" 
                        accept="image/*" 
                        id="imageInput" 
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <TextEditor 
                      onChange={(content) => this.setState({content: content})}
                    />
                  </FormGroup>
                  <FormGroup>
                    <TagsInput 
                      value={s.keywords} 
                      onChange={(productTags) => this.setState({keywords: productTags})} 
                      onlyUnique
                      inputProps={{
                        placeholder: 'الكلمات المفتاحية...'
                      }}
                      maxTags={10}
                    />
                  </FormGroup>
                  <DefaultButton 
                    block 
                    color={'dark'}
                    onClick={() => this.storePost()}
                    isLoading={s.addLoading}
                    disabled={s.addLoading || !s.image || !s.title || !s.content || !s.keywords || !s.lang}
                  >
                    حفظ
                  </DefaultButton>
                </CardBody>
              </Card>
            </Col>
          </Row>
        }

        {s.alertText &&
          <BottomLeftAlert color={'danger'}>{s.alertText}</BottomLeftAlert>
        }
      </Container>
    );
  }
}

export default withRouter(BlogAddPostPage);
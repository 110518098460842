import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import './DateRange.css';
import { DefaultButton } from '../../Buttons/default';

const DateRangePicker = (props) => {
  const handleDateChange = (date, isStartDate) => {
    if (isStartDate) {
      props.onSetStartDate(date);
    } else {
      props.onSetEndDate(date);
    }
  };

  return (
    <div className="date-range-picker">
      <b>تحديد التاريخ: </b>
      <DatePicker
        selected={props.startDate}
        onChange={(date) => handleDateChange(date, true)}
        selectsStart
        startDate={props.startDate}
        endDate={props.endDate}
        maxDate={new Date()}
      />
      <DatePicker
        selected={props.endDate}
        onChange={(date) => handleDateChange(date, false)}
        selectsEnd
        startDate={props.startDate}
        endDate={props.endDate}
        minDate={props.startDate}
        maxDate={new Date()}
      />
      <DefaultButton 
        className={'ms-2'} 
        size={'md'} 
        color={'dark'}
        onClick={() => props.onChange()}
      >
        تحديث
      </DefaultButton>
    </div>
  );
};

export default DateRangePicker;

DateRangePicker.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onChange: PropTypes.func,
  onSetStartDate: PropTypes.func,
  onSetEndDate: PropTypes.func
}
import React, { Component } from "react";
import { Button, Card, CardBody, Container, FormGroup, Label, Table } from "reactstrap";
import { FaTrash, FaRegEnvelopeOpen, FaRegEnvelope } from 'react-icons/fa';
import { AdminBodyTitle } from "../../components/Typography/Titles";
import { API_LINK } from "../../utils/constants";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { DefaultModal } from "../../components/Modals/Default";
import { DefaultInput } from "../../components/Forms/Inputs/default";

class MessagesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messagesData: null,
      messagesLoading: true,

      selectedMessage: null
    }
  }

  componentDidMount() {
    this.getMessages();
  }

  getMessages() {
    this.setState({messagesLoading: true});
    
    fetch(API_LINK+'/contactus', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        messagesData: json.messages,
        messagesLoading: false
      });
    })
  }

  read() {
    fetch(API_LINK+'/contactus/read', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        id: this.state.selectedMessage.id
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.updateReadedStatus(this.state.selectedMessage.id);
      }
    })
  }

  updateReadedStatus(id) {
    const { messagesData } = this.state;

    // Find the index of the message with the given id
    const messageIndex = messagesData.data.findIndex(
      (message) => message.id === id
    );

    // If the message is found, update the readed value
    if (messageIndex !== -1) {
      const updatedMessages = [...messagesData.data];
      updatedMessages[messageIndex].readed = 1; // Set to the desired value

      // Update the state with the new messages
      this.setState({
        messagesData: {
          ...messagesData,
          data: updatedMessages,
        }
      });
    }
  };

  render() {
    const s = this.state;

    return(
      <Container fluid>
        <AdminBodyTitle>الرسائل</AdminBodyTitle>
        <Card>
          <CardBody>
            <Table responsive className="table-bliz bg-light">
              <thead>
                <tr>
                  <th>#</th>
                  <th>الإسم الكامل</th>
                  <th>البريد الإلكتروني</th>
                  <th>عنوان الشبكة</th>
                  <th>سبب التواصل</th>
                  <th>خيارات</th>
                </tr>
              </thead>
              <tbody>
                {s.messagesLoading ?
                  <tr>
                    <td colSpan={6} align="center">
                      <AiOutlineLoading3Quarters className="animation-spin" />
                    </td>
                  </tr>
                :
                  s.messagesData?.data?.length > 0 ?
                    s.messagesData.data.map((data, key) => {
                      return(
                        <tr key={key} className={`${data.readed === 0 ? 'bg-light fw-bold' : 'bg-white'}`}>
                          <td>{ data.id }</td>
                          <td>{ data.fullname }</td>
                          <td>{ data.email }</td>
                          <td>{ data.ip}</td>
                          <td>{ data.subject }</td>
                          <td>
                            <Button className="btn btn-xs btn-success" onClick={() => this.setState({selectedMessage: data}, () => this.read())}>
                              {data.readed === 0 ?
                                <FaRegEnvelope />
                                :
                                <FaRegEnvelopeOpen />
                              }
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  :
                    <tr>
                      <td colSpan={6} align="center">لا توجد بيانات</td>
                    </tr>
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>

        <DefaultModal
          isOpen={s.selectedMessage}
          title={'تفاصيل الرسالة'}
          cancelText={'غلق'}
          toggle={() => this.setState({selectedMessage: null})}
        >
          <FormGroup>
            <Label>الإسم الكامل: </Label>
            <DefaultInput readOnly value={s.selectedMessage?.fullname} />
          </FormGroup>
          <FormGroup>
            <Label>سبب التواصل: </Label>
            <DefaultInput readOnly value={s.selectedMessage?.subject} />
          </FormGroup>
          <FormGroup>
            <Label>الرسالة: </Label>
            <DefaultInput type={'textarea'} readOnly value={s.selectedMessage?.message} />
          </FormGroup>
          <FormGroup>
            <Label>البريد الإلكتروني</Label>
            <DefaultInput readOnly value={s.selectedMessage?.email} />
          </FormGroup>
          <FormGroup>
            <Label>عنوان الشبكة: </Label>
            <DefaultInput readOnly value={s.selectedMessage?.ip} />
          </FormGroup>
          <FormGroup>
            <Label>تاريخ الرسالة: </Label>
            <DefaultInput readOnly value={s.selectedMessage?.created_at} />
          </FormGroup>
        </DefaultModal>
      </Container>
    );
  }
}

export default MessagesPage;
import React from "react";
import { BiChart, BiCog, BiHome } from 'react-icons/bi';
import { FiUsers } from 'react-icons/fi';
import { HiOutlineTemplate } from 'react-icons/hi';
import { TbPackages } from 'react-icons/tb';
import { AiOutlineTranslation } from 'react-icons/ai';
import { FaBlog, FaListOl } from "react-icons/fa6";
import { RiQuestionAnswerLine } from "react-icons/ri";
import { GiWorld } from 'react-icons/gi';
import './styles.css';
import { SidebarItem } from "./Item";
import { useLocation } from "react-router-dom";
import { DefaultButton } from "../Buttons/default";

const data = [
  {
    name: 'الرئيسية',
    link: '/dashboard/home',
    icon: <BiHome />,
    childrens: []
  },
  {
    name: 'التقارير',
    link: '/dashboard/reposts',
    icon: <BiChart />,
    childrens: [
      {
        name: 'الزيارات',
        link: '/dashboard/reports/visites'
      },
      {
        name: 'الأرباح',
        link: '/dashboard/reports/profit'
      }
    ]
  },
  {
    name: 'المستخدمين',
    link: '/dashboard/users',
    icon: <FiUsers />,
    childrens: []
  },
  {
    name: 'القوالب',
    link: '/dashboard/templates',
    icon: <HiOutlineTemplate />,
    childrens: [
      {
        name: 'التصنيفات',
        link: '/dashboard/template/categories'
      }, 
      {
        name: 'القوالب',
        link: '/dashboard/template/templates'
      },
      {
        name: 'أنواع الوحدات',
        link: '/dashboard/template/section/types'
      }
    ]
  },
  {
    name: 'الباقات',
    link: '/dashboard/packages',
    icon: <TbPackages />,
    childrens: [
      {
        name: 'التصنيفات',
        link: '/dashboard/package/categories'
      }, 
      {
        name: 'الأسعار',
        link: '/dashboard/package/pricing'
      }
    ]
  },
  {
    name: 'الدول',
    link: '/dashboard/countries',
    icon: <GiWorld />,
    childrens: []
  },
  {
    name: 'اللغات',
    link: '/dashboard/languages',
    icon: <AiOutlineTranslation />,
    childrens: []
  },
  {
    name: 'المدونة',
    link: '/dashboard/blog/posts',
    icon: <FaBlog />,
    childrens: []
  },
  {
    name: 'الأسئلة الشائعة',
    link: '/dashboard/qa',
    icon: <RiQuestionAnswerLine />,
    childrens: []
  },
  {
    name: 'إعدادات',
    link: '/dashboard/packages',
    icon: <BiCog />,
    childrens: [
      {
        name: 'طرق الدفع',
        link: '/dashboard/settings/payment_methods'
      }, 
      {
        name: 'الرسائل النصية',
        link: '/dashboard/settings/sms_services'
      }, 
      {
        name: 'GPT',
        link: '/dashboard/settings/gpt'
      }
    ]
  },
  {
    name: 'السجلات',
    link: '/dashboard/packages',
    icon: <FaListOl />,
    childrens: [
      {
        name: 'الزيارات',
        link: '/dashboard/logs/visites'
      },
      {
        name: 'الأحداث',
        link: '/dashboard/logs/events'
      },
      {
        name: 'تسجيلات الدخول',
        link: '/dashboard/logs/logins'
      }, 
      {
        name: 'جيبيتي',
        link: '/dashboard/logs/chat_gpt'
      }, 
      {
        name: 'رسائل التأكيد',
        link: '/dashboard/logs/register_codes'
      }
    ]
  }
];

export const Sidebar = ({ size, onClose }) => {
  const location = useLocation();

  return(
    <div className={"sidebar shadow-sm "+size} id="sidebar">
      <div className="sidebar-logo shadow-sm text-center">
        {size === 'toggled' ?
          <img src={ require('../../assets/brand-black.png') } height="40px" className="sidebar-brand-img" />
        :      
          <img src={ require('../../assets/logo-rtl-black.png') } height="50px" className="sidebar-logo-img" />
        }
        
        {window.innerWidth <= 792 && 
          <DefaultButton size={'md'} outline color={'dark'} onClick={() => onClose()}>X</DefaultButton>
        }
      </div>
      <ul className="sidebar-elements px-3 mt-3" id="sidebarElements">
        {data.map((data, key) => {
          return(
            <SidebarItem 
              key={key}
              name={data.name}
              link={data.link}
              icon={data.icon}
              childrens={data.childrens}
              currentLocation={location.pathname}
            />
          );
        })}
      </ul>
    </div>
  );
}
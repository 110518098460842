import React from "react";
import { Button } from "reactstrap";
import { HiOutlineLogout } from 'react-icons/hi';
import { IoPersonOutline } from 'react-icons/io5';
import { Link } from "react-router-dom";

export const ProfileCard = (props) => {
  return(
    <div className="home-card-profile py-4 px-3 bg-white shadow-sm">
      <div className="home-card-profile-img rounded-pill border border-2 border-dark my-2 p-3">
        <img src={ require('../../assets/brand-black.png') } width="100%" height="100%" />
      </div>
      <div className="home-card-profile-name h5 mb-4 text-center">
        محمد بوزرورة
      </div>
      <div className="home-card-profile-buttons text-center">
        <Link to="/dashboard/profile">
          <Button block outline color="info" className="mb-3">
            <IoPersonOutline />
            <span>حسابي</span>
          </Button>
        </Link>

        <Link to="/login">
          <Button block outline color="danger">
            <HiOutlineLogout />
            <span>خروج</span>
          </Button>
        </Link>
      </div>
    </div>
  );
}
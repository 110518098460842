import React, { Component } from "react";
import { Container } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { DefaultButton } from "../../../components/Buttons/default";
import MoyasarForm from "./Forms/Moyasar";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { API_LINK } from "../../../utils/constants";
import { BottomLeftAlert } from "../../../components/Alerts";
import PaypalForm from "./Forms/Paypal";

class PaymentMethodsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'moyasar',

      paymentSettingsData: null,
      paymentSettingsLoading: true,

      alertText: null
    }
  }

  componentDidMount() {
    this.getPaymentSettings();
  }

  getPaymentSettings() {
    this.setState({paymentSettingsLoading: true});
    
    fetch(API_LINK+'/settings/payments', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          paymentSettingsData: json.data,
          paymentSettingsLoading: false
        });
      }
    })
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <AdminBodyTitle>إعدادات طرق الدفع</AdminBodyTitle>

        {s.paymentSettingsLoading ?
          <div className="text-center py-5">
            <AiOutlineLoading3Quarters className="animation-spin" size={40} />
          </div>
        :
          <div>
            <div className="mb-4">
              <DefaultButton 
                className="mx-2 rounded-pill" 
                color={s.selectedTab === 'moyasar' ? 'dark' : 'light'}
                onClick={() => this.setState({selectedTab: 'moyasar'})}
              >
                ميسر
              </DefaultButton>

              <DefaultButton 
                className="mx-2 rounded-pill" 
                color={s.selectedTab === 'paypal' ? 'dark' : 'light'}
                onClick={() => this.setState({selectedTab: 'paypal'})}
              >
                باي بال
              </DefaultButton>
            </div>
    
            {s.selectedTab === 'paypal' ?
                <PaypalForm 
                  data={s.paymentSettingsData.paypal}
                  onSuccess={() => this.setState({alertText: 'لقد تم تحديث البيانات بنجاح.'})}
                />
              :
                <MoyasarForm 
                  data={s.paymentSettingsData.moyasar}
                  onSuccess={() => this.setState({alertText: 'لقد تم تحديث البيانات بنجاح.'})}
                />
            }
          </div>
        }

        {s.alertText &&
          <BottomLeftAlert color={'success'} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }
      </Container>
    );
  }
}

export default PaymentMethodsPage;
import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import NotFoundPage from "./pages/NotFound";
import HomePage from "./pages/Home";
import LoginPage from "./pages/Auth/Login";
import MainLayout from "./layouts";
import ProfilePage from "./pages/Profile";
import MessagesPage from "./pages/Messages";
import NotificationsPage from "./pages/Notifications";
import UsersPage from "./pages/Users";
import { API_LINK } from "./utils/constants";
import TemplateCategoriesPage from "./pages/Templates/Categories";
import TemplatesPage from "./pages/Templates/Templates";
import LanguagesPage from "./pages/Languages";
import CountriesPage from "./pages/Countries";
import UserPage from "./pages/Users/User";
import PackageCategoriesPage from "./pages/Packages/Categories";
import PackagePricingPage from "./pages/Packages/Pricing";
import TemplateSectionTypesPage from "./pages/Templates/SectionTypes";
import PaymentMethodsPage from "./pages/Settings/PaymentMethods";
import SMSServicesPage from "./pages/Settings/SMSServices";
import ReportsVisitesPage from "./pages/Reports/Visites";
import BlogPostsPage from "./pages/Blog/Posts";
import BlogAddPostPage from "./pages/Blog/Add";
import BlogEditPostPage from "./pages/Blog/Edit";
import QaPage from "./pages/Qa";
import ReportsProfitPage from "./pages/Reports/Profit";
import LogVisitesPage from "./pages/Logs/Visites";
import LogChatGPTPage from "./pages/Logs/ChatGPT";
import LogRegisterCodesPage from "./pages/Logs/RegisterCodes";
import LogLoginsPage from "./pages/Logs/Logins";
import LogEventsPage from "./pages/Logs/Events";
import LogVisitorPage from "./pages/Logs/Visitor";
import GPTSettingsPage from "./pages/Settings/GPT";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [ isAuthenticatedLoading, setIsAuthenticatedLoading ] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      const user = localStorage.getItem('user');
      if (user) {
        fetch(API_LINK+'/login/check', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            token: JSON.parse(user).token
          })
        })
        .then((response) => response.json())
        .then((json) => {
          if(json.success)
          {
            setIsAuthenticated(true);
            setIsAuthenticatedLoading(false);
          }
          else
          {
            setIsAuthenticated(false);
            setIsAuthenticatedLoading(false);
          }
        })
      }
      else
      {
        setIsAuthenticated(false);
        setIsAuthenticatedLoading(false);
      }
    };

    checkAuthentication();
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
  };

  if(isAuthenticatedLoading) {
    return(
      <div className="d-flex justify-content-center align-items-center" style={{minHeight: '100vh'}}>
        <img src={require('./assets/brand-black.png')} width="80px" className="animation-spin" />
      </div>
    );
  } else {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={isAuthenticated ? <Navigate to={'/dashboard/home'} /> : <LoginPage onLogin={handleLogin} />} />
          <Route path='/login' element={isAuthenticated ? <Navigate to={'/dashboard/home'} /> : <LoginPage onLogin={handleLogin} />} />

          <Route path='/dashboard' element={isAuthenticated ? <MainLayout onLogout={handleLogout} /> : <Navigate to={'/login'} />}>
            <Route path='/dashboard/home' element={<HomePage />} />
            <Route path='/dashboard/reports/visites' element={<ReportsVisitesPage />} />
            <Route path='/dashboard/reports/profit' element={<ReportsProfitPage />} />
            <Route path='/dashboard/users' element={<UsersPage />} />
            <Route path='/dashboard/user/:id' element={<UserPage />} />
            <Route path='/dashboard/profile' element={<ProfilePage />} />
            <Route path='/dashboard/messages' element={<MessagesPage />} />
            <Route path='/dashboard/notifications' element={<NotificationsPage />} />

            {/* templates */}
            <Route path='/dashboard/template/categories' element={<TemplateCategoriesPage />} />
            <Route path='/dashboard/template/templates' element={<TemplatesPage />} />
            <Route path='/dashboard/template/section/types' element={<TemplateSectionTypesPage />} />

            {/* packages */}
            <Route path='/dashboard/package/categories' element={<PackageCategoriesPage />} />
            <Route path='/dashboard/package/pricing' element={<PackagePricingPage />} />

            {/* languages */}
            <Route path='/dashboard/countries' element={<CountriesPage />} />
            <Route path='/dashboard/languages' element={<LanguagesPage />} />

            {/* blog */}
            <Route path='/dashboard/blog/posts' element={<BlogPostsPage />} />
            <Route path='/dashboard/blog/post/add' element={<BlogAddPostPage />} />
            <Route path='/dashboard/blog/post/edit/:id' element={<BlogEditPostPage />} />

            {/* qa */}
            <Route path='/dashboard/qa' element={<QaPage />} />

            {/* settings */}
            <Route path='/dashboard/settings/payment_methods' element={<PaymentMethodsPage />} />
            <Route path='/dashboard/settings/sms_services' element={<SMSServicesPage />} />
            <Route path='/dashboard/settings/gpt' element={<GPTSettingsPage />} />

            {/* logs */}
            <Route path='/dashboard/logs/visites' element={<LogVisitesPage />} />
            <Route path='/dashboard/logs/visitor/:ip' element={<LogVisitorPage />} />
            <Route path='/dashboard/logs/events' element={<LogEventsPage />} />
            <Route path='/dashboard/logs/logins' element={<LogLoginsPage />} />
            <Route path='/dashboard/logs/chat_gpt' element={<LogChatGPTPage />} />
            <Route path='/dashboard/logs/register_codes' element={<LogRegisterCodesPage />} />
          </Route>
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;

import React, { useState } from "react";
import { Card, CardBody, FormGroup, Input, Label } from "reactstrap";
import { DefaultInput } from "../../../../components/Forms/Inputs/default";
import { DefaultButton } from "../../../../components/Buttons/default";
import { API_LINK } from "../../../../utils/constants";

const MoyasarForm = ({data, onSuccess}) => {
  const [loading, setLoading] = useState(false);
  const [ state, setState ] = useState({
    status: data?.status,
    public_key: data?.public_key,
    secret_key: data?.secret_key
  });

  const update = () => {
    setLoading(true);

    fetch(API_LINK+'/settings/payment/moyasar/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        status: state.status,
        public_key: state.public_key,
        secret_key: state.secret_key
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        setLoading(false);
        onSuccess();
      }
    })
  }

  return(
    <Card>
      <CardBody>
        <FormGroup switch className="mb-4">
          <Input 
            type="switch" 
            checked={state.status}
            onChange={() => setState(prevState => ({...prevState, status: !prevState.status}))}
          />
          {state.status ? <span className="text-success fw-bold">مفعل</span> : <span className="fw-bold text-danger">معطل</span>}
        </FormGroup>
        <FormGroup>
          <Label>Public key: </Label>
          <DefaultInput 
            value={state.public_key}
            onChange={(event) => setState(prevState => ({...prevState, public_key: event.target.value}))}
          />
        </FormGroup>
        <FormGroup>
          <Label>Secret key: </Label>
          <DefaultInput 
            value={state.secret_key}
            onChange={(event) => setState(prevState => ({...prevState, secret_key: event.target.value}))}
          />
        </FormGroup>
        <DefaultButton 
          isLoading={loading} 
          block 
          color={'dark'}
          disabled={loading}
          onClick={() => update()}
        >
          حفظ
        </DefaultButton>
      </CardBody>
    </Card>
  );
}

export default MoyasarForm;
import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { AiOutlineEye } from 'react-icons/ai';
import { BsBoxes, BsPeople } from "react-icons/bs";
import { GrMoney } from "react-icons/gr";
import { IoMdLogIn } from "react-icons/io";
import { MdOutlineSms } from "react-icons/md";
import { RiRobot2Line } from "react-icons/ri";
import { StatisticsCard } from "../../components/Cards/Statistics";
import { ProfileCard } from "../../components/Cards/Profile";
import './styles.css';
import { AdminBodyTitle } from "../../components/Typography/Titles";
import { API_LINK } from "../../utils/constants";
import { Link } from "react-router-dom";
import { HiOutlineCursorClick } from "react-icons/hi";

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      
      todayVisits: 0,
      todayUsers: 0,
      todaySubscribers: 0,
      todayEarning: 0,
      todayLogins: 0,
      todayEvents: 0,
      todayOtps: 0,
      todayGpts: 0
    }
  }

  componentDidMount() {
    this.getTodayStatistics();
  }

  getTodayStatistics() {
    fetch(API_LINK+'/reports/today', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        todayVisits: json.visits,
        todayUsers: json.users,
        todaySubscribers: json.subscribers,
        todayEarning: json.earnings,
        todayLogins: json.logins,
        todayEvents: json.events,
        todayOtps: json.otps,
        todayGpts: json.gpts,
        isLoading: false
      });
    })
  }

  render() {
    const s = this.state;

    return(
      <Container fluid>
        <Row>
          <Col md="8">
            <AdminBodyTitle>إحصائيات اليوم</AdminBodyTitle>
            <Row>
              <Col md="6">
                <Link to={'/dashboard/logs/visites'} className="text-dark">
                  <StatisticsCard 
                    isLoading={s.isLoading}
                    title="زيارات"
                    value={s.todayVisits || 0}
                    icon={<AiOutlineEye className="home-card-statistics-icon float-end mt-4 me-3" size={50} />}
                  />
                </Link>
              </Col>
              <Col md="6">
                <StatisticsCard 
                  isLoading={s.isLoading}
                  title="تسجيلات المستخدمين"
                  value={s.todayUsers || 0}
                  icon={<BsPeople className="home-card-statistics-icon float-end mt-4 me-3" size={50} />}
                />
              </Col>
              <Col md="6">
                <StatisticsCard 
                  isLoading={s.isLoading}
                  title="المشتركين"
                  value={s.todaySubscribers || 0}
                  icon={<BsBoxes className="home-card-statistics-icon float-end mt-4 me-3" size={50} />}
                />
              </Col>
              <Col md="6">
                <StatisticsCard 
                  isLoading={s.isLoading}
                  title="الأرباح"
                  value={s.todayEarning?.toFixed(2) || 0+' ر.س'}
                  icon={<GrMoney className="home-card-statistics-icon float-end mt-4 me-3" size={50} />}
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md="6">
                <Link to={'/dashboard/logs/logins'} className="text-dark">
                  <StatisticsCard 
                    isLoading={s.isLoading}
                    title="تسجيلات الدخول"
                    value={s.todayLogins || 0}
                    icon={<IoMdLogIn className="home-card-statistics-icon float-end mt-4 me-3" size={50} />}
                  />
                </Link>
              </Col>
              <Col md="6">
                <Link to={'/dashboard/logs/events'} className="text-dark">
                  <StatisticsCard 
                    isLoading={s.isLoading}
                    title="الأحداث"
                    value={s.todayEvents || 0}
                    icon={<HiOutlineCursorClick className="home-card-statistics-icon float-end mt-4 me-3" size={50} />}
                  />
                </Link>
              </Col>
              <Col md="6">
                <Link to={'/dashboard/logs/register_codes'} className="text-dark">
                  <StatisticsCard 
                    isLoading={s.isLoading}
                    title="رسائل التأكيد"
                    value={s.todayOtps || 0}
                    icon={<MdOutlineSms className="home-card-statistics-icon float-end mt-4 me-3" size={50} />}
                  />
                </Link>
              </Col>
              <Col md="6">
                <Link to={'/dashboard/logs/chat_gpt'} className="text-dark">
                  <StatisticsCard 
                    isLoading={s.isLoading}
                    title="بناء بالذكاء الإصطناعي"
                    value={s.todayGpts || 0}
                    icon={<RiRobot2Line className="home-card-statistics-icon float-end mt-4 me-3" size={50} />}
                  />
                </Link>
              </Col>
            </Row>
          </Col>
          <Col md="4">
            <ProfileCard />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default HomePage
import React, { Component } from "react";
import { Card, CardBody, Col, Container, FormGroup, Label, Pagination, PaginationItem, PaginationLink, Row } from "reactstrap";
import { AdminBodyTitle } from "../../components/Typography/Titles";
import { DefaultButton } from "../../components/Buttons/default";
import { DefaultModal } from "../../components/Modals/Default";
import { DefaultInput } from "../../components/Forms/Inputs/default";
import { DefaultSelect } from "../../components/Forms/Inputs/Select";
import { API_LINK, MAIN_LINK } from "../../utils/constants";
import { BottomLeftAlert, DefaultAlert } from "../../components/Alerts";
import { AiOutlineEdit, AiOutlineEye, AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaTrash } from 'react-icons/fa';
import { ConfirmationModal } from "../../components/Modals/Confirmation";

class TemplatesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      templatesData: null,
      templatesLoading: true,
      currentPage: 1,

      categoriesData: null,
      categoriesLoading: true,

      languagesData: null,
      languagesLoading: true,

      name: null,
      category_id: null,
      lang: null,
      screenshot: null,
      file: null,

      dataToEdit: {},

      addModalShown: false,
      editModalShown: false,
      addLoading: false,
      editLoading: false,

      alertText: null,

      destroyLoading: false,
      destroyId: null
    }
  }

  componentDidMount() {
    this.getLanguages();
    this.getTemplates();
  }

  getLanguages() {
    fetch(API_LINK+'/languages', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        languagesData: json.languages,
        languagesLoading: false
      }, () => {
        this.getCategories();
      });
    })
  }

  getCategories() {
    this.setState({categoriesLoading: true});
    
    fetch(API_LINK+'/template/categories', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        categoriesData: json.categories,
        categoriesLoading: false
      });
    })
  }

  getTemplates() {
    this.setState({templatesLoading: true});
    
    fetch(API_LINK+'/templates?page='+this.state.currentPage, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        templatesData: json.templates,
        templatesLoading: false
      });
    })
  }

  store() {
    this.setState({addLoading: true});

    const formData = new FormData();
    formData.append('name', this.state.name);
    formData.append('category_id', this.state.category_id);
    formData.append('lang', this.state.lang);
    formData.append('screenshot', this.state.screenshot);
    formData.append('file', this.state.file);

    fetch(API_LINK+'/template/store', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: formData
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          addModalShown: false
        }, () => {
          this.getTemplates();
        });
      } else {
        this.setState({
          alertText: json.message
        });
      }
      this.setState({
        addLoading: false,
      });
    })
  }

  update() {
    this.setState({editLoading: true});
    console.log(this.state.dataToEdit);

    const formData = new FormData();
    formData.append('id', this.state.dataToEdit.id);
    formData.append('name', this.state.dataToEdit.name);
    formData.append('category_id', this.state.dataToEdit.category_id);
    formData.append('lang', this.state.dataToEdit.lang);
    if(this.state.dataToEdit.screenshot)
    {
      formData.append('screenshot', this.state.dataToEdit.screenshot);
    }
    if(this.state.dataToEdit.file)
    {
      formData.append('file', this.state.dataToEdit.file);
    }

    fetch(API_LINK+'/template/update', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: formData
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          editModalShown: false
        }, () => {
          this.getTemplates();
        });
      } else {
        this.setState({
          alertText: json.message
        });
      }
      this.setState({
        editLoading: false,
      });
    })
  }

  destroy() {
    this.setState({destroyLoading: true});

    fetch(API_LINK+'/template/destroy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        id: this.state.destroyId
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          destroyLoading: false,
          destroyId: null
        }, () => {
          this.getTemplates();
        });
      } else {
        this.setState({
          destroyLoading: false,
          destroyId: null,
          alertText: 'حدث خطأ أثناء إرسال البيانات، يرجى المحاولة لاحقا.'
        });
      }
    })
  }

  handleChange(event) {
    const {name, value} = event.target;

    this.setState({
      ...this.state,
      [name]: value
    });
  }

  handleEditChange(event) {
    const { name, value } = event.target;
  
    this.setState(prevState => ({
      dataToEdit: {
        ...prevState.dataToEdit,
        [name]: value
      }
    }));
  }

  handleFileChange(event) {
    const { name } = event.target;

    this.setState({
      ...this.state,
      [name]: event.target.files[0]
    });
  }

  handleEditFileChange(event) {
    const { name } = event.target;
  
    this.setState(prevState => ({
      dataToEdit: {
        ...prevState.dataToEdit,
        [name]: event.target.files[0]
      }
    }));
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <Row>
          <Col xs="6">
            <AdminBodyTitle>القوالب</AdminBodyTitle>
          </Col>
          <Col xs="6" className="text-end">
            <DefaultButton color={'success'} size={'md'} onClick={() => this.setState({addModalShown: true})}>+ إضافة قالب</DefaultButton>
          </Col>
        </Row>

        <hr />

        {s.templatesLoading ?
          <div className="text-center">
            <AiOutlineLoading3Quarters className="animation-spin h1" />
          </div>
        :
          s.templatesData?.data?.length > 0 ?
            <Row>
              {s.templatesData.data.map((data, key) => {
                return(
                  <Col md="4" className="mb-3" key={key}>
                    <Card>
                      <CardBody>
                        <div className="card-template-img-container">
                          <img src={MAIN_LINK+data.screenshot} width="100%" />
                        </div>
                        <h4 className="text-center my-3">{data.name}</h4>
                        <Row>
                          <Col xs="4">
                            <a href={MAIN_LINK+'/templates/'+data.template_path.split('/')[data.template_path.split('/').length - 1]+'/index.html'} target="_blank">
                              <DefaultButton block><AiOutlineEye /></DefaultButton>
                            </a>
                          </Col>
                          <Col xs="4">
                            <DefaultButton 
                              color={'success'} 
                              block 
                              onClick={() => this.setState({
                                editModalShown: true, 
                                dataToEdit: {
                                  id: data.id,
                                  lang: data.lang,
                                  name: data.name,
                                  category_id: data.category_id
                                }
                              })}
                            >
                              <AiOutlineEdit />
                            </DefaultButton>
                          </Col>
                          <Col xs="4">                    
                            <DefaultButton 
                              color={'danger'} 
                              block 
                              onClick={() => this.setState({destroyId: data.id})}
                            >
                              <FaTrash />
                            </DefaultButton>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          :
            <DefaultAlert color={'secondary'}>لا توجد بيانات</DefaultAlert>
        }

        {s.templatesData && s.templatesData.data && s.templatesData.data.length > 0 &&
          <Pagination>
            <PaginationItem>
              <PaginationLink previous href="#" />
            </PaginationItem>
            {[...Array(s.templatesData.last_page)].map((data, key) => {
              return(
                <PaginationItem key={key} onClick={() => s.currentPage === key+1 ? null : this.setState({currentPage: key+1}, () => { this.getTemplates() })}>
                  <PaginationLink href="#">
                    {key+1}
                  </PaginationLink>
                </PaginationItem>
              );
            })}
            <PaginationItem>
              <PaginationLink next href="#" />
            </PaginationItem>
          </Pagination>
        }

        {/* alert */}
        {s.alertText &&
          <BottomLeftAlert color={'danger'}>{s.alertText}</BottomLeftAlert>
        }
        
        <DefaultModal
          title={'إضافة قالب'}
          isOpen={s.addModalShown}
          toggle={() => this.setState({addModalShown: null})}
          confirmationText={'حفظ'}
          cancelText={'غلق'}
          isLoading={s.addLoading}
          onConfirm={() => this.store()}
        >
          <FormGroup>
            <Label>إسم القالب: </Label>
            <DefaultInput
              value={s.name}
              name={'name'}
              onChange={(event) => this.handleChange( event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>التصنيف: </Label>
            <DefaultSelect
              value={s.category_id}
              name={'category_id'}
              onChange={(event) => this.handleChange(event)}
            >
              <option value="">إختر التصنيف</option>
              {s.categoriesData && s.categoriesData.length > 0 &&
                s.categoriesData.map((data, key) => {
                  return(
                    <option value={data.id} key={key}>{data.name}</option>
                  );
                })
              }
            </DefaultSelect>
          </FormGroup>
          <FormGroup>
            <Label>اللغة: </Label>
            <DefaultSelect
              value={s.lang}
              name={'lang'}
              onChange={(event) => this.handleChange(event)}
            >
              <option value="">إختر اللغة</option>
              {s.languagesData && s.languagesData.length > 0 &&
                s.languagesData.map((data, key) => {
                  return(
                    <option value={data.short} key={key}>{data.name}</option>
                  );
                })
              }
            </DefaultSelect>
          </FormGroup>
          <FormGroup>
            <Label>الصورة الرئيسية: </Label>
            <DefaultInput 
              type="file"
              name={'screenshot'}
              onChange={(event) => this.handleFileChange( event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>الملف (zip): </Label>
            <DefaultInput 
              type="file" 
              name={'file'}
              onChange={(event) => this.handleFileChange(event)}
            />
          </FormGroup>
        </DefaultModal>

        {/** Edit Template */}
        <DefaultModal
          title={'تعديل قالب'}
          isOpen={s.editModalShown}
          toggle={() => this.setState({editModalShown: null})}
          confirmationText={'حفظ'}
          cancelText={'غلق'}
          isLoading={s.editLoading}
          onConfirm={() => this.update()}
        >
          <FormGroup>
            <Label>إسم القالب: </Label>
            <DefaultInput
              value={s.dataToEdit.name}
              name={'name'}
              onChange={(event) => this.handleEditChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>التصنيف: </Label>
            <DefaultSelect
              value={s.dataToEdit.category_id}
              name={'category_id'}
              onChange={(event) => this.handleEditChange(event)}
            >
              <option value="">إختر التصنيف</option>
              {s.categoriesData && s.categoriesData.length > 0 &&
                s.categoriesData.map((data, key) => {
                  return(
                    <option value={data.id} key={key}>{data.name}</option>
                  );
                })
              }
            </DefaultSelect>
          </FormGroup>
          <FormGroup>
            <Label>اللغة: </Label>
            <DefaultSelect
              value={s.dataToEdit.lang}
              name={'lang'}
              onChange={(event) => this.handleEditChange(event)}
            >
              <option value="">إختر اللغة</option>
              {s.languagesData && s.languagesData.length > 0 &&
                s.languagesData.map((data, key) => {
                  return(
                    <option value={data.code} key={key}>{data.name} ({data.code})</option>
                  );
                })
              }
            </DefaultSelect>
          </FormGroup>
          <FormGroup>
            <Label>الصورة الرئيسية: </Label>
            <DefaultInput 
              type="file"
              name={'screenshot'}
              onChange={(event) => this.handleEditFileChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>الملف (zip): </Label>
            <DefaultInput 
              type="file" 
              name={'file'}
              onChange={(event) => this.handleEditFileChange(event)}
            />
          </FormGroup>
        </DefaultModal>

        <ConfirmationModal
          isOpen={s.destroyId}
          confirmationText={'نعم'}
          cancelText={'لا'}
          toggle={() => this.setState({destroyId: null})}
          onConfirm={() => this.destroy()}
          isLoading={s.destroyLoading}
        >
          هل حقا تريد حذف القالب؟ سيتم حذف كل مكوناته وملفاته.
        </ConfirmationModal>
      </Container>
    );
  }
}

export default TemplatesPage;
import React, { Component } from "react";
import { Card, CardBody, Col, Container, FormGroup, Input, Row } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import TextEditor from "../../../components/TextEditor";
import { DefaultSelect } from "../../../components/Forms/Inputs/Select";
import { DefaultButton } from "../../../components/Buttons/default";
import TagsInput from 'react-tagsinput'
import { AiOutlineCamera, AiOutlineLoading3Quarters } from "react-icons/ai";
import 'react-tagsinput/react-tagsinput.css'
import { API_LINK, MAIN_LINK } from "../../../utils/constants";
import { withRouter } from "../../../components/Router/withRouter";
import { BottomLeftAlert } from "../../../components/Alerts";

class BlogEditPostPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      languagesData: null,
      languagesLoading: true,

      postLoading: true,
      updateLoading: false,

      title: '',
      image: null,
      content: '',
      keywords: [],
      lang: '',

      alertText: null
    }
  }

  componentDidMount() {
    this.getPost();
  }

  getPost() {
    this.setState({postLoading: true});
    fetch(API_LINK+'/blog/post/'+this.props.params.id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        title: json.post.title,
        keywords: json.post.keywords ? json.post.keywords.split(',') : [],
        image: json.post.image,
        content: json.post.content,
        lang: json.post.lang,
        postLoading: false
      }, () => {
        this.getLanguages();
      });
    })
  }

  getLanguages() {
    fetch(API_LINK+'/languages', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        languagesData: json.languages,
        languagesLoading: false
      });
    })
  }

  updatePost() {
    this.setState({updateLoading: true});

    const formData = new FormData();
    formData.append('id', this.props.params.id);
    formData.append('image', this.state.image?.includes('storage') ? '' : this.state.image);
    formData.append('title', this.state.title);
    formData.append('content', this.state.content);
    formData.append('lang', this.state.lang);
    formData.append('keywords', this.state.keywords);

    fetch(API_LINK+'/blog/post/update', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: formData
    })
    .then((response) => response.json())
    .then((json) => {
      console.log(json);
      if(json.success) {
        this.props.navigate('/dashboard/blog/posts');
      } else {
        this.setState({
          alertText: json.message,
          updateLoading: false
        });
      }
    })
  }

  handleChange(event) {
    const { name, value, type } = event.target;

    this.setState((prevState) => ({
      ...prevState,
      [name]: type === 'file' ? event.target.files[0] : value
    }));
  }

  render() {
    const s = this.state;

    return(
      <Container className="pb-5">
        <AdminBodyTitle>تعديل المقال</AdminBodyTitle>

        {s.languagesLoading || s.postLoading ?
          <div className="text-center">
            <AiOutlineLoading3Quarters className="animation-spin h2" />
          </div>
        :
          <Row>
            <Col md="8" className="offset-md-2">
              <Card>
                <CardBody>
                  <FormGroup>
                    <DefaultSelect
                      value={s.lang}
                      name={'lang'}
                      onChange={(event) => this.handleChange(event)}
                    >
                      <option value="">اختر اللغة</option>
                      {s.languagesData && s.languagesData.length > 0 &&
                        s.languagesData.map((data, key) => {
                          return(
                            <option value={data.short} key={key}>{data.name}</option>
                          );
                        })
                      }
                    </DefaultSelect>
                  </FormGroup>
                  <FormGroup>
                    <DefaultInput 
                      placeholder={'عنوان المقال'} 
                      name={'title'}
                      value={s.title}
                      onChange={(event) => this.handleChange(event)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <div className="blogpost-image-selection bg-light cursor-pointer" onClick={() => document.getElementById('imageInput').click()}>
                      {s.image ?
                        <img src={s.image.includes('storage') ? MAIN_LINK+s.image : URL.createObjectURL(s.image)} width={'100%'} />
                      :
                        <div className="text-center py-5">
                          <AiOutlineCamera size={160} />
                        </div>
                      }
                      <Input 
                        type="file" 
                        name="image" 
                        onChange={(event) => this.handleChange(event)} 
                        className="d-none" 
                        accept="image/*" 
                        id="imageInput" 
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <TextEditor 
                      onChange={(content) => this.setState({content: content})}
                      value={s.content}
                    />
                  </FormGroup>
                  <FormGroup>
                    <TagsInput 
                      value={s.keywords} 
                      onChange={(productTags) => this.setState({keywords: productTags})} 
                      onlyUnique
                      inputProps={{
                        placeholder: 'الكلمات المفتاحية...'
                      }}
                      maxTags={10}
                    />
                  </FormGroup>
                  <DefaultButton 
                    block 
                    color={'dark'}
                    onClick={() => this.updatePost()}
                    isLoading={s.updateLoading}
                    disabled={s.updateLoading || !s.image || !s.title || !s.content || !s.keywords || !s.lang}
                  >
                    حفظ
                  </DefaultButton>
                </CardBody>
              </Card>
            </Col>
          </Row>
        }

        {s.alertText &&
          <BottomLeftAlert color={'danger'}>{s.alertText}</BottomLeftAlert>
        }
      </Container>
    );
  }
}

export default withRouter(BlogEditPostPage);
import React, { Component } from "react";
import { Card, CardBody, Col, Container, FormGroup, Label, Row, Table } from "reactstrap";
import { AdminBodyTitle } from "../../components/Typography/Titles";
import { DefaultButton } from "../../components/Buttons/default";
import { API_LINK, MAIN_LINK } from "../../utils/constants";
import { AiFillEdit, AiOutlineEye, AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { BottomLeftAlert } from "../../components/Alerts";
import { DefaultModal } from "../../components/Modals/Default";
import { DefaultInput } from "../../components/Forms/Inputs/default";
import { ConfirmationModal } from "../../components/Modals/Confirmation";
import { DefaultSelect } from "../../components/Forms/Inputs/Select";

class QaPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questionsData: null,
      questionsLoading: true,
      addModalShown: null,
      editModalShown: null,

      languagesData: null,
      languagesLoading: true,

      dataToAdd: {},
      dataToEdit: {},

      addLoading: false,

      questionToDestroy: null,
      destroyLoading: false,

      alertText: null
    }
  }

  componentDidMount() {
    this.getQuestions();
  }

  getLanguages() {
    fetch(API_LINK+'/languages', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        languagesData: json.languages,
        languagesLoading: false
      });
    })
  }

  getQuestions() {
    this.setState({questionsLoading: true});
    
    fetch(API_LINK+'/qa', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          questionsData: json.questions,
          questionsLoading: false
        }, () => {
          this.getLanguages();
        });
      }
    })
  }

  storeQuestion() {
    this.setState({addLoading: true});

    fetch(API_LINK+'/qa/store', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        lang: this.state.dataToAdd.lang,
        question: this.state.dataToAdd.question,
        answer: this.state.dataToAdd.answer
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          addLoading: false,
          dataToAdd: {},
          addModalShown: false
        }, () => {
          this.getQuestions();
        });
      } else {
        this.setState({
          alertText: json.message
        });
      }
    })
  }

  updateQuestion() {
    this.setState({addLoading: true});

    fetch(API_LINK+'/qa/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        id: this.state.dataToEdit.id,
        lang: this.state.dataToEdit.lang,
        question: this.state.dataToEdit.question,
        answer: this.state.dataToEdit.answer
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          addLoading: false,
          dataToEdit: {},
          editModalShown: false
        }, () => {
          this.getQuestions();
        });
      } else {
        this.setState({
          alertText: json.message
        });
      }
    })
  }

  destroy() {
    this.setState({destroyLoading: true});
    
    fetch(API_LINK+'/qa/destroy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        id: this.state.questionToDestroy
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          questionToDestroy: null,
          destroyLoading: false
        }, () => {
          this.getQuestions();
        });
      }
    })
  }

  handleChange(event) {
    const { name, value, type } = event.target;

    this.setState((prevState) => ({
      dataToAdd: {
        ...prevState.dataToAdd,
        [name]: type === 'file' ? event.target.files[0] : value
      }
    }));
  }

  handleEditChange(event) {
    const { name, value, type } = event.target;

    this.setState((prevState) => ({
      dataToEdit: {
        ...prevState.dataToEdit,
        [name]: type === 'file' ? event.target.files[0] : value
      }
    }));
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <Row className="d-flex align-items-center">
          <Col md="8">
            <AdminBodyTitle>الأسئلة الشائعة</AdminBodyTitle>
          </Col>
          <Col md="4" className="text-end">
            <DefaultButton color={'dark'} onClick={() => this.setState({addModalShown: true})}>+ إضافة جديد</DefaultButton>
          </Col>
        </Row>

        <Card>
          <CardBody>
            <Table responsive className="table-bliz bg-light">
              <thead>
                <tr>
                  <th>#</th>
                  <th>السؤال</th>
                  <th>الجواب</th>
                  <th>خيارات</th>
                </tr>
              </thead>
              <tbody>
                {s.questionsLoading ?
                  <tr>
                    <td colSpan={4} align="center">
                      <AiOutlineLoading3Quarters className="animation-spin" />
                    </td>
                  </tr>
                :
                  s.questionsData?.length > 0 ?
                    s.questionsData.map((data, key) => (
                      <tr key={key}>
                        <td>{data.id}</td>
                        <td>{data.question}</td>
                        <td>{data.answer}</td>
                        <td>
                          <a href={MAIN_LINK} target="_blank">
                            <DefaultButton size={'xs'} color={'info'}>
                              <AiOutlineEye />
                            </DefaultButton>
                          </a>
                          {' '}
                          <DefaultButton size={'xs'} color={'success'} onClick={() => this.setState({dataToEdit: data, editModalShown: true})}>
                            <AiFillEdit />
                          </DefaultButton>
                          {' '}
                          <DefaultButton size={'xs'} color={'danger'} onClick={() => this.setState({questionToDestroy: data.id})}>
                            <MdDelete />
                          </DefaultButton>
                        </td>
                      </tr>
                    ))
                  :
                    <tr>
                      <td colSpan={4} align="center">
                        لا توجد بيانات
                      </td>
                    </tr>
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>

        {s.alertText &&
          <BottomLeftAlert color={'danger'}>{s.alertText}</BottomLeftAlert>
        }

        <DefaultModal
          title={'إضافة سؤال'}
          isOpen={s.addModalShown}
          toggle={() => this.setState({addModalShown: false})}
          confirmationText={'حفظ'}
          cancelText={'غلق'}
          isLoading={s.addLoading}
          onConfirm={() => this.storeQuestion()}
        >
          <FormGroup>
            <DefaultSelect
              value={s.dataToAdd?.lang}
              name={'lang'}
              onChange={(event) => this.handleChange(event)}
            >
              <option value="">اختر اللغة</option>
              {s.languagesData && s.languagesData.length > 0 &&
                s.languagesData.map((data, key) => {
                  return(
                    <option value={data.short} key={key}>{data.name}</option>
                  );
                })
              }
            </DefaultSelect>
          </FormGroup>
          <FormGroup>
            <Label>السؤال: </Label>
            <DefaultInput
              type={'textarea'}
              name={'question'}
              value={s.dataToAdd?.question}
              onChange={(event) => this.handleChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>الجواب: </Label>
            <DefaultInput
              type={'textarea'}
              name={'answer'}
              value={s.dataToAdd?.answer}
              onChange={(event) => this.handleChange(event)}
            />
          </FormGroup>
        </DefaultModal>

        <DefaultModal
          title={'تعديل السؤال'}
          isOpen={s.editModalShown}
          toggle={() => this.setState({editModalShown: false})}
          confirmationText={'حفظ'}
          cancelText={'غلق'}
          isLoading={s.addLoading}
          onConfirm={() => this.updateQuestion()}
        >
          <FormGroup>
            <DefaultSelect
              value={s.dataToEdit?.lang}
              name={'lang'}
              onChange={(event) => this.handleEditChange(event)}
            >
              <option value="">اختر اللغة</option>
              {s.languagesData && s.languagesData.length > 0 &&
                s.languagesData.map((data, key) => {
                  return(
                    <option value={data.short} key={key}>{data.name} ({data.code})</option>
                  );
                })
              }
            </DefaultSelect>
          </FormGroup>
          <FormGroup>
            <Label>السؤال: </Label>
            <DefaultInput
              type={'textarea'}
              name={'question'}
              value={s.dataToEdit?.question}
              onChange={(event) => this.handleEditChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>الجواب: </Label>
            <DefaultInput
              type={'textarea'}
              name={'answer'}
              value={s.dataToEdit?.answer}
              onChange={(event) => this.handleEditChange(event)}
            />
          </FormGroup>
        </DefaultModal>

        <ConfirmationModal
          isOpen={s.questionToDestroy}
          confirmationText={'نعم'}
          cancelText={'لا'}
          toggle={() => this.setState({questionToDestroy: null})}
          onConfirm={() => this.destroy()}
          isLoading={s.destroyLoading}
        >
          هل حقا تريد حذف السؤال؟
        </ConfirmationModal>
      </Container>
    );
  }
}

export default QaPage;
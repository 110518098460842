import React, { useState } from "react";
import { AiOutlineEye, AiOutlineMenu, AiOutlineHistory, AiOutlineCheckSquare } from 'react-icons/ai';
import { BsBell, BsEnvelope } from 'react-icons/bs';
import { HiOutlineLogout } from "react-icons/hi";
import { IoPersonOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import './styles.css';
import { MAIN_LINK } from "../../utils/constants";

export const Header = (props) => {
  const [profileDropdownShown, setProfileDropdownShown] = useState(false);

  return(
    <div>
      <header className="clearfix shadow-sm">
        <Container fluid>
          <AiOutlineMenu 
            className="header-sidebar-toggle-btn float-start mt-2 pt-1 cursor-pointer" 
            onClick={() => props.onSidebarToggle()}
          />
          <a href={MAIN_LINK} target="_blank">
            <button className="header-visite-btn float-start btn btn-outline-dark border-dark my-2 mx-3">
              <AiOutlineEye />{' '}
              <span>عرض الموقع</span>
            </button>
          </a>
          <ul className="header-list clearfix float-end">
            <Dropdown 
              isOpen={profileDropdownShown} 
              toggle={() => setProfileDropdownShown(!profileDropdownShown)} 
              className="float-end header-list-profile"
            >
              <DropdownToggle caret>
                <img src={ require('../../assets/brand-black.png') } width="28px" className="border border-2 border-muted rounded-pill p-1 bg-white" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link to="/dashboard/profile" className="text-dark"><IoPersonOutline /> <span>إعدادات الحساب</span></Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/login" className="text-dark"><HiOutlineLogout /> <span>خروج</span></Link>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <Link to="/dashboard/notifications" className="header-notification-icon float-end">
              <span className="bliz-badge bg-danger"></span>
              <BsBell className="h4 text-dark" />
            </Link>

            <Link to="/dashboard/messages" className="header-message-icon float-end">
              <span className="bliz-badge bg-danger"></span>
              <BsEnvelope className="h4 text-dark" />
            </Link>
          </ul>
        </Container>
      </header>
    </div>
  );
}
import React from "react";
import { Input } from "reactstrap";
import propTypes from 'prop-types';

export const DefaultSelect = (props) => {
  return(
    <Input {...props} type="select" className="bliz-form-control" bsSize="lg">
      {props.children}
    </Input>
  );
}

DefaultSelect.propTypes = {
  onChange: propTypes.func,
  value: propTypes.string
}
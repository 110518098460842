import React, { Component } from "react";
import { Card, CardBody, Container, FormGroup, Input, Label } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import { BottomLeftAlert } from "../../../components/Alerts";
import { API_LINK } from "../../../utils/constants";
import { DefaultButton } from "../../../components/Buttons/default";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

class GPTSettingsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gptLoading: true,
      status: null,
      secret: null,

      storeLoading: false,

      alertText: null
    }
  }

  componentDidMount() {
    this.getGPTSettings();
  }

  getGPTSettings() {
    this.setState({gptLoading: true});
    
    fetch(API_LINK+'/settings/gpt', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          status: json.gpt?.status,
          secret: json.gpt?.secret,
          gptLoading: false
        });
      }
    })
  }

  update() {
    this.setState({storeLoading: true});

    fetch(API_LINK+'/settings/gpt/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        status: this.state?.status,
        secret: this.state?.secret
      })
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        storeLoading: false,
        alertText: 'لقد تم تعديل البيانات بنجاح'
      });
    })
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <AdminBodyTitle>إعدادات GPT</AdminBodyTitle>

        {s.gptLoading ?
          <div className="text-center py-5">
            <AiOutlineLoading3Quarters className="animation-spin" size={40} />
          </div>
        :
          <Card>
            <CardBody>
              <FormGroup switch className="mb-4">
                <Input 
                  type="switch" 
                  checked={s?.status}
                  onChange={() => this.setState({status: !s.status})}
                />
                {s?.status ? <span className="text-success fw-bold">مفعل</span> : <span className="fw-bold text-danger">معطل</span>}
              </FormGroup>
              <FormGroup>
                <Label>Secret: </Label>
                <DefaultInput 
                  value={s.secret}
                  onChange={(event) => this.setState({secret: event.target.value})}
                />
              </FormGroup>
              <DefaultButton 
                isLoading={s.storeLoading} 
                block 
                color={'dark'}
                disabled={s.storeLoading}
                onClick={() => this.update()}
              >
                حفظ
              </DefaultButton>
            </CardBody>
          </Card>
        }

        {s.alertText &&
          <BottomLeftAlert color={'success'} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }
      </Container>
    );
  }
}

export default GPTSettingsPage;
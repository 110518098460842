import React, { Component } from "react";
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";
import { AiOutlineLoading3Quarters, AiOutlineUser, AiOutlineCheck } from 'react-icons/ai';
import { FaBan } from 'react-icons/fa';
import { BsPeople } from "react-icons/bs";
import { AdminBodyTitle } from "../../components/Typography/Titles";
import { API_LINK } from "../../utils/constants";
import moment from "moment";
import { DefaultButton } from "../../components/Buttons/default";
import { ConfirmationModal } from "../../components/Modals/Confirmation";
import { Link } from "react-router-dom";
import { StatisticsCard } from "../../components/Cards/Statistics";
import { BottomLeftAlert } from "../../components/Alerts";

class UsersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usersData: null,
      usersLoading: true,

      subscribers: null,
      activeNow: null,

      userToBan: null,
      banLoading: false,

      userToActivate: null,
      activateLoading: false,

      currentPage: 1,

      alertText: null
    }
  }

  componentDidMount() {
    this.getUsers();
  }

  getUsers() {
    this.setState({usersLoading: true});
    
    fetch(API_LINK+'/users?page='+this.state.currentPage, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        usersData: json.users,
        subscribers: json.subscribers,
        activeNow: json.activeNow,
        usersLoading: false
      });
    })
  }

  ban() {
    this.setState({banLoading: true});
    fetch(API_LINK+'/user/ban', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        id: this.state.userToBan
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          banLoading: false,
          userToBan: null
        }, () => {
          this.getUsers();
        });
      } else {
        this.setState({
          banLoading: false,
          userToBan: null,
          alertText: json.message
        });
      }
    })
  }

  activate() {
    this.setState({activateLoading: true});
    fetch(API_LINK+'/user/activate', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        id: this.state.userToActivate
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          activateLoading: false,
          userToActivate: null
        }, () => {
          this.getUsers();
        });
      } else {
        this.setState({
          activateLoading: false,
          userToActivate: null,
          alertText: json.message
        });
      }
    })
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <AdminBodyTitle>المستخدمين</AdminBodyTitle>
        <Row>
          <Col md="4">
            <StatisticsCard 
              isLoading={s.usersLoading}
              title={'عدد المستخدمين'}
              value={s.usersData?.total}
              icon={<BsPeople className="home-card-statistics-icon float-end mt-4 me-3" size={50} />}
            />
          </Col>
          <Col md="4">
            <StatisticsCard 
              isLoading={s.usersLoading}
              title={'عدد المشتركين'}
              value={s.subscribers}
              icon={<BsPeople className="home-card-statistics-icon float-end mt-4 me-3" size={50} />}
            />
          </Col>
          <Col md="4">
            <StatisticsCard 
              isLoading={s.usersLoading}
              title={'المتواجدين الآن'}
              value={s.activeNow}
              icon={<BsPeople className="home-card-statistics-icon float-end mt-4 me-3" size={50} />}
            />
          </Col>
        </Row>
        <Card>
          <CardHeader>
            
          </CardHeader>
          <CardBody>
            <Table responsive className="table-bliz bg-light">
              <thead>
                <tr>
                  <th>#</th>
                  <th>الإسم الكامل</th>
                  <th>البريد الإلكتروني</th>
                  <th>رقم الهاتف</th>
                  <th>عدد المواقع</th>
                  <th>تاريخ التسجيل</th>
                  <th>خيارات</th>
                </tr>
              </thead>
              <tbody>
                {s.usersLoading ?
                  <tr>
                    <td colSpan={7} align="center">
                      <AiOutlineLoading3Quarters className="animation-spin" />
                    </td>
                  </tr>
                :
                  !s.usersData || s.usersData.length <= 0 ?
                    <tr>
                      <td colSpan={7} align="center">
                        لا توجد بيانات
                      </td>
                    </tr>
                  :
                    s.usersData.data.map((data, key) => {
                      return(
                        <tr key={key} style={data.status === 'blocked' ? {color: 'red'} : null}>
                          <td>{ data.id }</td>
                          <td>{ data.firstname } { data.lastname }</td>
                          <td>{ data.email }</td>
                          <td>{ data.country_code+data.phone_number }</td>
                          <td>{ data.websites?.length || 0 }</td>
                          <td>{ moment(data.created_at).format('YYYY-MM-DD | HH:mm') }</td>
                          <td>
                            <Link to={'/dashboard/user/'+data.id}>
                              <DefaultButton className="btn-xs" color={'primary'}>
                                <AiOutlineUser />
                              </DefaultButton>
                            </Link>
                            {' '}
                            {data.status === 'blocked' ?
                              <DefaultButton className="btn-xs" color={'success'} onClick={() => this.setState({userToActivate: data.id})}>
                                <AiOutlineCheck />
                              </DefaultButton>
                            :
                            
                              <DefaultButton className="btn-xs" color={'warning'} onClick={() => this.setState({userToBan: data.id})}>
                                <FaBan />
                              </DefaultButton>
                            }
                          </td>
                        </tr>
                      );
                    })
                }
              </tbody>
            </Table>
          </CardBody>

          {s.usersData && s.usersData.data && s.usersData.data.length > 0 &&
            <CardFooter>
              <Pagination>
                <PaginationItem>
                  <PaginationLink previous href="#" />
                </PaginationItem>
                {[...Array(s.usersData.last_page)].map((data, key) => {
                  return(
                    <PaginationItem onClick={() => s.currentPage === key+1 ? null : [this.setState({currentPage: key+1}), this.getUsers()]}>
                      <PaginationLink href="#">
                        {key+1}
                      </PaginationLink>
                    </PaginationItem>
                  );
                })}
                <PaginationItem>
                  <PaginationLink next href="#" />
                </PaginationItem>
              </Pagination>
            </CardFooter>
          }
        </Card>

        {/* alert */}
        {s.alertText &&
          <BottomLeftAlert color={'danger'}>{s.alertText}</BottomLeftAlert>
        }

        {/* ban user modal START */}
        <ConfirmationModal
          isOpen={s.userToBan}
          confirmationText={'نعم'}
          cancelText={'لا'}
          onConfirm={() => this.ban()}
          toggle={() => this.setState({userToBan: null})}
          isLoading={s.banLoading}
        >
          هل حقا تريد حظر المستخدم؟
        </ConfirmationModal>
        {/* ban user modal END */}

        {/* activate user modal START */}
        <ConfirmationModal
          isOpen={s.userToActivate}
          confirmationText={'نعم'}
          cancelText={'لا'}
          onConfirm={() => this.activate()}
          toggle={() => this.setState({userToActivate: null})}
          isLoading={s.activateLoading}
        >
          هل حقا تريد تفعيل المستخدم؟
        </ConfirmationModal>
        {/* activate user modal END */}
      </Container>
    );
  }
}

export default UsersPage;
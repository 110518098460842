import React, { Component } from "react";
import { Card, CardBody, Col, Container, FormGroup, Label, Row, Table } from "reactstrap";
import { AiOutlinePlus, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { withRouter } from "../../../components/Router/withRouter";
import { API_LINK, MAIN_LINK } from "../../../utils/constants";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import { DefaultButton } from "../../../components/Buttons/default";
import { FaBan, FaCheck } from "react-icons/fa";
import { PasswordInput } from "../../../components/Forms/Inputs/Password";
import { BottomLeftAlert, DefaultAlert } from "../../../components/Alerts";
import { ConfirmationModal } from "../../../components/Modals/Confirmation";
import { DefaultModal } from "../../../components/Modals/Default";
import { DefaultSelect } from "../../../components/Forms/Inputs/Select";
import { StatisticsCard } from "../../../components/Cards/Statistics";
import { RiLoginCircleFill, RiLogoutCircleFill } from "react-icons/ri";

class UserPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: null,
      userLoading: true,

      new_password: '',
      new_password_confirmation: '',
      updatePasswordLoading: false,

      alertText: null,
      alertType: null,

      banLoading: false,
      banModalShown: false,

      activateLoading: false,
      activateModalShown: false,

      packagesData: null,
      packagesLoading: false,
      addSubscribtionLoading: false,
      addSubscribtionModalShown: false,
      subscribtionPackageId: '',
      subscribtionWebsiteId: '',
      subscribtionStartDate: '',
      subscribtionEndDate: ''
    }
  }

  componentDidMount() {
    this.getUser();
  }

  getUser() {
    this.setState({usersLoading: true});

    fetch(API_LINK+'/user/'+this.props.params.id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        userData: json.user,
        userLoading: false
      });
    })
  }

  getPackages() {
    this.setState({packagesLoading: true});
    
    fetch(API_LINK+'/packages', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        packagesData: json.packages,
        packagesLoading: false
      });
    })
  }

  storeSubscribtion() {
    this.setState({addSubscribtionLoading: true});

    fetch(API_LINK+'/user/subscribtion/store', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        package_id: this.state.subscribtionPackageId,
        website_id: this.state.subscribtionWebsiteId,
        start_date: this.state.subscribtionStartDate,
        end_date: this.state.subscribtionEndDate
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          addSubscribtionLoading: false,
          addSubscribtionModalShown: false,
          package_id: '',
          start_date: '',
          end_date: ''
        }, () => {
          this.getUser();
        });
      } else {
        this.setState({
          alertText: json.message,
          alertType: 'danger',
          addSubscribtionLoading: false
        });
      }
    })
  }

  updatePassword() {
    this.setState({updatePasswordLoading: true});

    fetch(API_LINK+'/user/password/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        id: this.props.params.id,
        new_password: this.state.new_password,
        new_password_confirmation: this.state.new_password_confirmation
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          updatePasswordLoading: false,
          new_password: '',
          new_password_confirmation: '',
          alertText: json.message,
          alertType: 'success'
        });
      } else {
        this.setState({
          updatePasswordLoading: false,
          alertText: json.message,
          alertType: 'danger'
        });
      }
    })
  }

  ban() {
    this.setState({banLoading: true});
    fetch(API_LINK+'/user/ban', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        id: this.props.params.id
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          banLoading: false,
          banModalShown: null
        }, () => {
          this.getUser();
        });
      } else {
        this.setState({
          banLoading: false,
          banModalShown: false,
          alertText: json.message,
          alertType: 'danger'
        });
      }
    })
  }

  activate() {
    this.setState({activateLoading: true});
    fetch(API_LINK+'/user/activate', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        id: this.props.params.id
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          activateLoading: false,
          activateModalShown: null
        }, () => {
          this.getUser();
        });
      } else {
        this.setState({
          activateLoading: false,
          activateModalShown: false,
          alertText: json.message,
          alertType: 'danger'
        });
      }
    })
  }

  handleChange(event) {
    const { name, value } = event.target;

    this.setState({
      ...this.state,
      [name]: value
    });
  }

  render() {
    const s = this.state;

    return(
      <Container className="pb-5">
        {s.userLoading ?
          <div className="text-center">
            <AiOutlineLoading3Quarters className="animation-spin h1" />
          </div>
        :
          <Row>
            <Col md="4">
              <StatisticsCard 
                title={'المداخيل'}
                value={s.userData?.in+' ر.س'}
                icon={<RiLoginCircleFill className="home-card-statistics-icon float-end mt-4 me-3" size={50} />}
              />
            </Col>
            <Col md="4">
              <StatisticsCard 
                title={'المصاريف'}
                value={s.userData?.out.toFixed(2)+' ر.س'}
                icon={<RiLogoutCircleFill className="home-card-statistics-icon float-end mt-4 me-3" size={50} />}
              />
            </Col>
            <Col md="4"></Col>
            <Col md="7">
              <Card>
                <CardBody>
                  <h4>معلومات رئيسية</h4>
                  <div className="text-center mb-5 mt-3">
                    <img src={MAIN_LINK+s.userData.profile_picture_path || require('../../../assets/brand-black.png')} alt="" width="200px" height="200px" />
                  </div>
                  <div>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label>الإسم: </Label>
                          <DefaultInput type="text" value={s.userData?.firstname} readOnly />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>اللقب: </Label>
                          <DefaultInput type="text" value={s.userData?.lastname} readOnly />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>البريد الإلكتروني: </Label>
                          <DefaultInput type="text" name="email" value={s.userData?.email} readOnly />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>رقم الهاتف: </Label>
                          <DefaultInput type="text" dir="ltr" name="phone_number" value={s.userData?.country_code+s.userData?.phone_number} readOnly />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label>تاريخ التسجيل: </Label>
                          <DefaultInput type="text" readonly value={s.userData.created_at.split('T')[0]} readOnly />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        {s.userData.status === 'blocked' ?
                          <DefaultButton color={'success'} block onClick={() => this.setState({activateModalShown: true})}>
                            <FaCheck />{' '}
                            تنشيط المستخدم
                          </DefaultButton>
                        :
                        
                          <DefaultButton color={'warning'} block onClick={() => this.setState({banModalShown: true})}>
                            <FaBan />{' '}
                            حظر المستخدم
                          </DefaultButton>
                        }
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
              
              <Card className="my-4">
                <CardBody>
                  <h4 className="mb-3">الملفات</h4>
                  <h6>عدد المجلدات: {s.userData?.folders.length}</h6>
                  <h6>عدد الملفات: {s.userData?.files.length}</h6>
                </CardBody>
              </Card>
            </Col>
            <Col md="5">
              <Card className="mb-4">
                <CardBody>
                  <h4>إعدادات الحماية</h4>
                  <FormGroup>
                    <Label>كلمة المرور الجديدة: </Label>
                    <PasswordInput 
                       value={s.new_password}
                       name={'new_password'}
                       onChange={(event) => this.handleChange(event)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>تأكيد كلمة المرور الجديدة: </Label>
                    <PasswordInput
                      value={s.new_password_confirmation}
                      name={'new_password_confirmation'}
                      onChange={(event) => this.handleChange(event)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <DefaultButton 
                      color={'danger'} 
                      block
                      disabled={s.updatePasswordLoading || s.new_password.length < 8 || s.new_password_confirmation < 8 || s.new_password !== s.new_password_confirmation}
                      isLoading={s.updatePasswordLoading}
                      onClick={() => this.updatePassword()}
                    >
                      حفظ
                    </DefaultButton>
                  </FormGroup>
                </CardBody>
              </Card>

              <Card className="mb-4">
                <CardBody>
                  <Row className="mb-3">
                    <Col xs="6">
                      <h4>الإشتراكات</h4>
                    </Col>
                    <Col xs="6" className="text-end">
                      <DefaultButton className="btn-xs" color={'dark'} onClick={() => this.setState({addSubscribtionModalShown: true}, () => this.getPackages())}>
                        <AiOutlinePlus />
                      </DefaultButton>
                    </Col>
                  </Row>

                  {s.userData.websites?.length > 0 ?
                    <Table responsive className="table-bliz bg-light">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>الموقع</th>
                          <th>الباقة</th>
                          <th>البداية</th>
                          <th>النهاية</th>
                        </tr>
                      </thead>
                      <tbody>
                        {s.userData.websites.map((website, key) => (
                          website.subscribtions?.length > 0 &&
                            website.subscribtions.map((sub, subKey) => (
                              <tr key={subKey}>
                                <td>{sub.id}</td>
                                <td>
                                  <a href={MAIN_LINK+'/w/'+s.userData.username+'/'+website?.name} target="_blank">{website.name}</a>
                                </td>
                                <td>{sub.package.name}</td>
                                <td>{sub.start_date}</td>
                                <td>{sub.end_date}</td>
                              </tr>
                            ))
                        ))}
                      </tbody>
                    </Table>
                  :
                    <DefaultAlert color={'secondary'}>لا توجد بيانات</DefaultAlert>
                  }
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <h4 className="mb-3">المواقع</h4>
                  {s.userData.websites?.length > 0 ?
                    <Table responsive className="table-bliz bg-light">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>إسم الموقع</th>
                          <th>التصنيف</th>
                          <th>القالب</th>
                        </tr>
                      </thead>
                      <tbody>
                        {s.userData.websites.map((data, key) => (
                          <tr key={key}>
                            <td>{data.id}</td>
                            <td>
                              <a href={MAIN_LINK+'/w/'+s.userData.username+'/'+data?.name} target="_blank">{data.name}</a>
                            </td>
                            <td>{data.category?.name}</td>
                            <td>{data?.template?.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  :
                    <DefaultAlert color={'secondary'}>لا توجد بيانات</DefaultAlert>
                  }
                </CardBody>
              </Card>

              <Card className="my-4">
                <CardBody>
                  <h4 className="mb-3">البحث عن الدومينات</h4>
                  {s.userData?.domain_search_histories?.length > 0 &&
                    s.userData.domain_search_histories.map((data, key) => (
                      <h6><span className="text-success">{ data.domain }</span> | {data.created_at}</h6>
                    ))
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        }

        {s.alertText &&
          <BottomLeftAlert color={s.alertType}>{s.alertText}</BottomLeftAlert>
        }

        {/* add subscribtion modal START */}
        <DefaultModal
          isOpen={s.addSubscribtionModalShown}
          confirmationText={'حفظ'}
          cancelText={'غلق'}
          isLoading={s.packagesLoading || s.addSubscribtionLoading}
          onConfirm={() => this.storeSubscribtion()}
          toggle={() => this.setState({addSubscribtionModalShown: false})}
        >
          <FormGroup>
            <Label>الباقة: </Label>
            <DefaultSelect
              name="subscribtionPackageId"
              onChange={(event) => this.handleChange(event)}
            >
              <option value="">اختر باقة</option>
              {s.packagesData?.length > 0 &&
                s.packagesData.map((data, key) => (
                  <option value={data.id} key={key}>{data.name}</option>
                ))
              }
            </DefaultSelect>
          </FormGroup>
          <FormGroup>
            <Label>الموقع: </Label>
            <DefaultSelect
              name="subscribtionWebsiteId"
              onChange={(event) => this.handleChange(event)}
            >
              <option value="">اختر الموقع</option>
              {s.userData?.websites?.length > 0 &&
                s.userData.websites.map((data, key) => (
                  <option value={data.id} key={key}>{data.name}</option>
                ))
              }
            </DefaultSelect>
          </FormGroup>
          <FormGroup>
            <Label>تاريخ البداية: </Label>
            <DefaultInput 
              type={'date'} 
              name="subscribtionStartDate"
              onChange={(event) => this.handleChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>تاريخ النهاية: </Label>
            <DefaultInput 
              type={'date'} 
              name="subscribtionEndDate"
              onChange={(event) => this.handleChange(event)}
            />
          </FormGroup>
        </DefaultModal>
        {/* add subscribtion modal END */}

        {/* ban user modal START */}
        <ConfirmationModal
          isOpen={s.banModalShown}
          confirmationText={'نعم'}
          cancelText={'لا'}
          onConfirm={() => this.ban()}
          toggle={() => this.setState({banModalShown: false})}
          isLoading={s.banLoading}
        >
          هل حقا تريد حظر المستخدم؟
        </ConfirmationModal>
        {/* ban user modal END */}

        {/* activate user modal START */}
        <ConfirmationModal
          isOpen={s.activateModalShown}
          confirmationText={'نعم'}
          cancelText={'لا'}
          onConfirm={() => this.activate()}
          toggle={() => this.setState({activateModalShown: false})}
          isLoading={s.activateLoading}
        >
          هل حقا تريد تفعيل المستخدم؟
        </ConfirmationModal>
        {/* activate user modal END */}
      </Container>
    );
  }
}

export default withRouter(UserPage);
import React, { useState } from "react";
import { Card, CardBody, FormGroup, Label } from "reactstrap";
import { PasswordInput } from "../Forms/Inputs/Password";
import { DefaultButton } from "../Buttons/default";
import { API_LINK } from "../../utils/constants";

export const SecuritySettingsForm = (props) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const update = () => {
    setLoading(true);
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/account/security/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        current_password: data.current_password,
        new_password: data.new_password,
        new_password_confirmation: data.new_password_confirmation
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        setData({current_password: '', new_password: '', new_password_confirmation: ''});
        localStorage.setItem('user', JSON.stringify(json.user));
        props.setUserData();
        props.setAlert(json.message, 'success');
      } else {
        props.setAlert(json.message, 'danger');
      }
      setLoading(false);
    })
  }

  return(
    <Card className="rounded-0 border-0 mb-3">
      <CardBody>
        <h4 className="fw-bold">إعدادات الحماية</h4>
        <FormGroup>
          <Label>كلمة المرور الحالية: </Label>
          <PasswordInput 
            name="current_password" 
            onChange={(event) => handleInputChange(event)} 
            value={data.current_password}
          />
        </FormGroup>
        <FormGroup>
          <Label>كلمة المرور الجديدة: </Label>
          <PasswordInput 
            name="new_password" 
            onChange={(event) => handleInputChange(event)} 
            value={data.new_password}
          />
        </FormGroup>
        <FormGroup>
          <Label>تأكيد كلمة المرور الجديدة: </Label>
          <PasswordInput 
            name="new_password_confirmation" 
            onChange={(event) => handleInputChange(event)} 
            value={data.new_password_confirmation}
          />
        </FormGroup>
        <FormGroup>
          <DefaultButton 
            block 
            size="lg" 
            color="danger"
            isLoading={loading}
            onClick={() => update()}
          >
            حفظ
          </DefaultButton>
        </FormGroup>
      </CardBody>
    </Card>
  );
}
import React from "react";
import { Input } from "reactstrap";
import propTypes from 'prop-types';
import "./default.css"

export const DefaultInput = (props) => {
  return(
    <Input 
      {...props} 
      className={'bliz-form-control '+props.className || ''} 
      bsSize={props.size ? props.size : 'lg'} 
    />
  );
}

DefaultInput.propTypes = {
  size: propTypes.string,
  value: propTypes.string,
  onChange: propTypes.func,
  name: propTypes.string,
  type: propTypes.string,
  className: propTypes.string,
  placeholder: propTypes.string,
  maxLength: propTypes.number,
  readOnly: propTypes.bool
}
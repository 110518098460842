import React, { Component } from "react";
import { Container } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { DefaultButton } from "../../../components/Buttons/default";
import OurSMSForm from "./Forms/OurSMS";
import TwilioForm from "./Forms/TwilioForm";
import { API_LINK } from "../../../utils/constants";
import { BottomLeftAlert } from "../../../components/Alerts";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

class SMSServicesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'oursms',

      smsSettingsData: null,
      smsSettingsLoading: true,

      alertText: null
    }
  }

  componentDidMount() {
    this.getSmsSettings();
  }

  getSmsSettings() {
    this.setState({smsSettingsLoading: true});
    
    fetch(API_LINK+'/settings/sms', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          smsSettingsData: json.data,
          smsSettingsLoading: false
        });
      }
    })
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <AdminBodyTitle>إعدادات الرسائل النصية</AdminBodyTitle>

        {s.smsSettingsLoading ?
          <div className="text-center py-5">
            <AiOutlineLoading3Quarters className="animation-spin" size={40} />
          </div>
        :
          <div>
            <div className="mb-4">
              <DefaultButton 
                className="mx-2 rounded-pill" 
                color={s.selectedTab === 'oursms' ? 'dark' : 'light'}
                onClick={() => this.setState({selectedTab: 'oursms'})}
              >
                OurSMS
              </DefaultButton>
    
              <DefaultButton 
                className="mx-2 rounded-pill" 
                color={s.selectedTab === 'twilio' ? 'dark' : 'light'}
                onClick={() => this.setState({selectedTab: 'twilio'})}
              >
                Twilio
              </DefaultButton>
            </div>
    
            {s.selectedTab === 'oursms' ?
              <OurSMSForm 
                data={s.smsSettingsData.oursms}
                onSuccess={() => this.setState({alertText: 'لقد تم تحديث البيانات بنجاح.'})}
              />
            :
              <TwilioForm 
                data={s.smsSettingsData.twilio}
                onSuccess={() => this.setState({alertText: 'لقد تم تحديث البيانات بنجاح.'})}
              />
            }
          </div>
        }

        {s.alertText &&
          <BottomLeftAlert color={'success'} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }
      </Container>
    );
  }
}

export default SMSServicesPage;
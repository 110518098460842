import React from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import PropTypes from 'prop-types';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { DefaultButton } from "../Buttons/default";
import './styles.css';

export const ConfirmationModal = (props) => {
  return(
    <Modal isOpen={props.isOpen} toggle={props.toggle} centered {...props}>
        {props.isLoading ?
          <ModalBody className="text-center">
            <AiOutlineLoading3Quarters className="animation-spin h1" />
          </ModalBody>
        :
          <ModalBody>
            <div className="mb-3">
              <h3 className="text-center fw-bold">{props.children}</h3>
            </div>
            <Row>
              <Col xs="6">
                <DefaultButton color="dark" onClick={props.onConfirm} block>
                  {props.confirmationText}
                </DefaultButton>
              </Col>
              <Col xs="6">
                <DefaultButton color="danger" onClick={props.toggle} block>
                  {props.cancelText}
                </DefaultButton>
              </Col>
            </Row>
          </ModalBody>
        }
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onConfirm: PropTypes.func,
  confirmationText: PropTypes.string,
  cancelText: PropTypes.string,
  isLoading: PropTypes.bool
}
import React, { Component } from "react";
import { Card, CardBody, Container, Table } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { API_LINK } from "../../../utils/constants";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

class LogLoginsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginsData: null,
      loginsLoading: true
    }
  }

  componentDidMount() {
    this.getLogins();
  }

  getLogins() {
    this.setState({loginsLoading: true});
    
    fetch(API_LINK+'/logs/logins', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        loginsData: json.logins,
        loginsLoading: false
      });
    })
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <AdminBodyTitle>سجل آخر 100 دخول</AdminBodyTitle>

        <Card>
          <CardBody>
            <Table responsive className="table-bliz bg-light">
              <thead>
                <tr>
                  <th>#</th>
                  <th>البريد الإلكتروني</th>
                  <th>الحالة</th>
                  <th>عنوان الشبكة</th>
                  <th>التاريخ</th>
                </tr>
              </thead>
              <tbody>
              {s.loginsLoading ?
                  <tr>
                    <td colSpan={5} align="center">
                      <AiOutlineLoading3Quarters className="animation-spin" />
                    </td>
                  </tr>
                :
                  !s.loginsData || s.loginsData.length <= 0 ?
                    <tr>
                      <td colSpan={5} align="center">
                        لا توجد بيانات
                      </td>
                    </tr>
                  :
                    s.loginsData.map((data, key) => {
                      return(
                        <tr key={key}>
                          <td>{data.id}</td>
                          <td>{data.email}</td>
                          <td>{data.status === 1 ? <span className="text-success">ناجحة</span> : <span className="text-danger">فاشلة</span>}</td>
                          <td>{data.ip}</td>
                          <td>{data.created_at}</td>
                        </tr>
                      );
                    })
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default LogLoginsPage;
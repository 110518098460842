import React, { Component } from "react";
import { Card, CardBody, Container, Table } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { API_LINK } from "../../../utils/constants";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

class LogEventsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventsData: null,
      eventsLoading: true
    }
  }

  componentDidMount() {
    this.getEvents();
  }

  getEvents() {
    this.setState({eventsLoading: true});
    
    fetch(API_LINK+'/logs/events', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        eventsData: json.events,
        eventsLoading: false
      });
    })
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <AdminBodyTitle>سجل آخر 100 حدث</AdminBodyTitle>

        <Card>
          <CardBody>
            <Table responsive className="table-bliz bg-light">
              <thead>
                <tr>
                  <th>#</th>
                  <th>عنوان النافذة</th>
                  <th>الحدث</th>
                  <th>عنوان الشبكة</th>
                  <th>نوع الجهاز</th>
                  <th>المتصفح</th>
                  <th>نظام التشغيل</th>
                  <th>موديل الجهاز</th>
                  <th>التاريخ</th>
                </tr>
              </thead>
              <tbody>
              {s.eventsLoading ?
                  <tr>
                    <td colSpan={10} align="center">
                      <AiOutlineLoading3Quarters className="animation-spin" />
                    </td>
                  </tr>
                :
                  !s.eventsData || s.eventsData.length <= 0 ?
                    <tr>
                      <td colSpan={10} align="center">
                        لا توجد بيانات
                      </td>
                    </tr>
                  :
                    s.eventsData.map((data, key) => {
                      return(
                        <tr key={key}>
                          <td>{data.id}</td>
                          <td>{data.window_title}</td>
                          <td>{data.name}</td>
                          <td>{data.ip}</td>
                          <td title={data.device_resolutions}>{data.device_resolutions?.split('x')[0] <= 600 ? <span className="text-warning">جوال</span> : <span className="text-info">كمبيوتر</span>}</td>
                          <td>{data.browser_name}</td>
                          <td>{data.os}</td>
                          <td>{data.device_model}</td>
                          <td>{data.created_at.split('T')[0]}</td>
                        </tr>
                      );
                    })
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default LogEventsPage;
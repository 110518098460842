import React, { useEffect, useState } from "react";
import { Card, CardBody, FormGroup, Label } from "reactstrap";
import { DefaultButton } from "../Buttons/default";
import { DefaultInput } from "../Forms/Inputs/default";
import { PasswordInput } from "../Forms/Inputs/Password";
import { API_LINK } from "../../utils/constants";

export const EmailSettingsForm = (props) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));

    setData({
      currentEmail: user.email
    });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const update = () => {
    setLoading(true);
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/account/email/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        current_email: data.currentEmail,
        new_email: data.newEmail,
        password: data.password
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        localStorage.setItem('user', JSON.stringify(json.user));
        setData({currentEmail: json.user.email, newEmail: '', password: ''});
        props.setUserData();
        props.setAlert(json.message, 'success');
      } else {
        props.setAlert(json.message, 'danger');
      }
      setLoading(false);
    })
  }

  return(
    <Card className="rounded-0 border-0 mb-3">
      <CardBody>
        <h4 className="fw-bold">إعدادات البريد الإلكتروني</h4>
        <form>
          <FormGroup>
            <Label>البريد الإلكتروني الحالي: </Label>
            <DefaultInput 
              type="email" 
              name="currentEmail" 
              value={data.currentEmail} 
              onChange={(event) => handleInputChange(event)} 
            />
          </FormGroup>
          <FormGroup>
            <Label>البريد الإلكتروني الجديد: </Label>
            <DefaultInput 
              type="email" 
              name="newEmail" 
              value={data.newEmail} 
              onChange={(event) => handleInputChange(event)} 
            />
          </FormGroup>
          <FormGroup>
            <Label>كلمة المرور: </Label>
            <PasswordInput 
              name="password" 
              value={data.password} 
              onChange={(event) => handleInputChange(event)} 
            />
          </FormGroup>
          <FormGroup>
            <DefaultButton 
              color="dark" 
              block
              isLoading={loading}
              onClick={() => update()}
            >
              حفظ
            </DefaultButton>
          </FormGroup>
        </form>
      </CardBody>
    </Card>
  );
}
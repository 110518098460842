import React, { Component, Fragment } from "react";
import { Card, CardBody, Col, Container, FormGroup, Label, Row, Table } from "reactstrap";
import { AiOutlineLoading3Quarters, AiFillEdit, AiOutlineFileDone } from 'react-icons/ai';
import { AdminBodyTitle } from "../../components/Typography/Titles";
import { DefaultInput } from "../../components/Forms/Inputs/default";
import { DefaultSelect } from "../../components/Forms/Inputs/Select";
import { DefaultButton } from "../../components/Buttons/default";
import { API_LINK } from "../../utils/constants";
import { BottomLeftAlert } from "../../components/Alerts";
import { DefaultModal } from "../../components/Modals/Default";

class LanguagesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      languagesData: null,
      languagesLoading: true,

      addLanguageLoading: false,

      name: null,
      short: null,
      code: null,
      direction: null,
      status: null,

      setDefaultLanguageLoading: false,
      defaultLanguage: null,

      selectedToEdit: null,
      updateLanguageLoading: false,

      alertText: null
    }
  }

  componentDidMount() {
    this.getLanguages();
  }

  getLanguages() {
    this.setState({languagesLoading: true});
    fetch(API_LINK+'/languages', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        languagesData: json.languages,
        defaultLanguage: json.default ? json.default.id : '',
        languagesLoading: false
      });
    })
  }

  storeLanguage() {
    this.setState({addLanguageLoading: true});
    fetch(API_LINK+'/language/store', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        name: this.state.name,
        short: this.state.short,
        code: this.state.code,
        direction: this.state.direction,
        status: this.state.status
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          addLanguageLoading: false,
          name: '',
          short: '',
          code: '',
          direction: '',
          status: ''
        }, () => {
          this.getLanguages();
        });
      } else {
        this.setState({
          alertText: json.message
        });
      }
    })
  }

  updateLanguage() {
    this.setState({updateLanguageLoading: true});
    fetch(API_LINK+'/language/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        id: this.state.selectedToEdit.id,
        name: this.state.selectedToEdit.name,
        short: this.state.selectedToEdit.short,
        code: this.state.selectedToEdit.code,
        direction: this.state.selectedToEdit.direction,
        status: this.state.selectedToEdit.status
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          updateLanguageLoading: false,
          selectedToEdit: null
        }, () => {
          this.getLanguages();
        });
      } else {
        this.setState({
          alertText: json.message
        });
      }
    })
  }

  setDefaultLanguage() {
    this.setState({setDefaultLanguageLoading: true});
    fetch(API_LINK+'/language/default/set', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        id: this.state.defaultLanguage,
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          setDefaultLanguageLoading: false
        }, () => {
          this.getLanguages();
        });
      } else {
        this.setState({
          alertText: json.message,
          setDefaultLanguageLoading: false
        });
      }
    })
  }

  handleChange(name, event) {
    this.setState({
      ...this.state,
      [name]: event.target.value
    });
  }

  handleEditChange(name, event) {
    this.setState((prevState) => ({
      selectedToEdit: {
        ...prevState.selectedToEdit,
        [name]: event.target.value,
      },
    }));
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <AdminBodyTitle>اللغات</AdminBodyTitle>
        <Row>
          <Col md="4">
            <Card className="mb-3">
              <CardBody>
                <h4>اللغة الإفتراضية</h4>
                {s.languagesLoading ?
                  <div className="text-center h4 my-4">
                    <AiOutlineLoading3Quarters className="animation-spin" />
                  </div>
                :
                  <Fragment>
                    <FormGroup>
                      <Label>اللغة: </Label>
                      <DefaultSelect value={s.defaultLanguage} onChange={(event) => this.handleChange('defaultLanguage', event)}>
                        <option value="">إختر اللغة الإفتراضية</option>
                        {s.languagesData && s.languagesData.length > 0 &&
                          s.languagesData.map((data, key) => {
                            return(
                              <option value={data.id} key={key}>{ data.name }</option>
                            );
                          })
                        }
                      </DefaultSelect>
                    </FormGroup>
                    <FormGroup>
                      <DefaultButton 
                        color={'dark'} 
                        block
                        isLoading={s.setDefaultLanguageLoading}
                        disabled={s.setDefaultLanguageLoading}
                        onClick={() => this.setDefaultLanguage()}
                      >
                        حفظ
                      </DefaultButton>
                    </FormGroup>
                  </Fragment>
                }
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <h4>إضافة لغة</h4>
                <FormGroup>
                  <Label>إسم اللغة: </Label>
                  <DefaultInput 
                    type="text" 
                    value={s.name}
                    onChange={(event) => this.handleChange('name', event)} 
                  />
                </FormGroup>
                <FormGroup>
                  <Label>إختصار اللغة: </Label>
                  <DefaultInput 
                    type="text" 
                    value={s.short}
                    onChange={(event) => this.handleChange('short', event)} 
                  />
                </FormGroup>
                <FormGroup>
                  <Label>كود اللغة: </Label>
                  <DefaultInput 
                    value={s.code}
                    type="text" 
                    onChange={(event) => this.handleChange('code', event)} 
                  />
                </FormGroup>
                <FormGroup>
                  <Label>الإتجاه: </Label>
                  <DefaultSelect onChange={(event) => this.handleChange('direction', event)} value={s.direction}>
                    <option value="">اختر الإتجاه</option>
                    <option value="rtl">من اليمين لليسار</option>
                    <option value="ltr">من اليسار لليمين</option>
                  </DefaultSelect>
                </FormGroup>
                <FormGroup>
                  <Label>الحالة: </Label>
                  <DefaultSelect onChange={(event) => this.handleChange('status', event)} value={s.status}>
                    <option value="">إختر الحالة</option>
                    <option value="1">نشطة</option>
                    <option value="0">معطلة</option>
                  </DefaultSelect>
                </FormGroup>
                <FormGroup>
                  <DefaultButton 
                    color={'dark'} 
                    block
                    isLoading={s.addLanguageLoading}
                    disabled={!s.name || !s.code || !s.short || !s.direction || !s.status || s.addLanguageLoading}
                    onClick={() => this.storeLanguage()}
                  >
                    حفظ
                  </DefaultButton>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
          <Col md="8">
            <Card>
              <CardBody>
                <h4>كل اللغات</h4>
                <Table responsive className="table-bliz bg-light">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>الإسم</th>
                      <th>الإختصار</th>
                      <th>الكود</th>
                      <th>الإتجاه</th>
                      <th>إفتراضية</th>
                      <th>الحالة</th>
                      <th>خيارات</th>
                    </tr>
                  </thead>
                  <tbody>
                    {s.languagesLoading ?
                      <tr>
                        <td colSpan={8} align="center">
                          <AiOutlineLoading3Quarters className="animation-spin" />
                        </td>
                      </tr>
                    :
                      !s.languagesData || s.languagesData <= 0 ?
                        <tr>
                          <td colSpan={8} align="center">
                            لا توجد بيانات
                          </td>
                        </tr>
                      :
                        s.languagesData.map((data, key) => {
                          return(
                            <tr key={key}>
                              <td>{ data.id }</td>
                              <td>{ data.name }</td>
                              <td>{ data.short }</td>
                              <td>{ data.code }</td>
                              <td>{ data.direction }</td>
                              <td>
                                {Number(data.is_default) === 1 ? <span className="text-success">نعم</span> : <span className="text-danger">لا</span>}
                              </td>
                              <td>
                                {data.status === 0 ? <span className="text-danger">معطلة</span> : <span className="text-success">نشطة</span>}
                              </td>
                              <td>
                                <DefaultButton className="btn-xs" color={'info'}>
                                  <AiOutlineFileDone />
                                </DefaultButton>
                                {' '}
                                <DefaultButton className="btn-xs" color={'success'} onClick={() => this.setState({selectedToEdit: data})}>
                                  <AiFillEdit />
                                </DefaultButton>
                              </td>
                            </tr>
                          );
                        })
                    }
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* alert */}
        {s.alertText &&
          <BottomLeftAlert color={'danger'}>{s.alertText}</BottomLeftAlert>
        }

        {/* edit modal */}
        <DefaultModal 
          title={'تعديل اللغة'} 
          isOpen={s.selectedToEdit} 
          toggle={() => this.setState({selectedToEdit: null})}
          onConfirm={() => this.updateLanguage()}
          confirmationText={'حفظ'}
          cancelText={'غلق'}
          isLoading={s.updateLanguageLoading}
        >
          <FormGroup>
            <Label>إسم اللغة: </Label>
            <DefaultInput 
              type="text" 
              value={s.selectedToEdit?.name}
              onChange={(event) => this.handleEditChange('name', event)} 
            />
          </FormGroup>
          <FormGroup>
            <Label>إختصار اللغة: </Label>
            <DefaultInput 
              type="text" 
              value={s.selectedToEdit?.short}
              onChange={(event) => this.handleEditChange('short', event)} 
            />
          </FormGroup>
          <FormGroup>
            <Label>كود اللغة: </Label>
            <DefaultInput 
              type="text" 
              value={s.selectedToEdit?.code}
              onChange={(event) => this.handleEditChange('code', event)} 
            />
          </FormGroup>
          <FormGroup>
            <Label>الإتجاه: </Label>
            <DefaultSelect onChange={(event) => this.handleEditChange('direction', event)} value={s.selectedToEdit?.direction}>
              <option value="">اختر الإتجاه</option>
              <option value="rtl">من اليمين لليسار</option>
              <option value="ltr">من اليسار لليمين</option>
            </DefaultSelect>
          </FormGroup>
          <FormGroup>
            <Label>الحالة: </Label>
            <DefaultSelect onChange={(event) => this.handleEditChange('status', event)} value={s.selectedToEdit?.status}>
              <option value="">إختر الحالة</option>
              <option value="1">نشطة</option>
              <option value="0">معطلة</option>
            </DefaultSelect>
          </FormGroup>
        </DefaultModal>
      </Container>
    );
  }
}

export default LanguagesPage;
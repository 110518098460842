import React, { Component } from "react";
import { Alert, Col, Container, Label, Row } from "reactstrap";
import { DefaultButton } from "../../../components/Buttons/default";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import "./index.css"
import { PasswordInput } from "../../../components/Forms/Inputs/Password";
import { withRouter } from "../../../components/Router/withRouter";
import { API_LINK } from "../../../utils/constants";

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      email: '',
      password: '',
      validateEmail: false,

      alertText: null
    }
  }

  handleChange(name, event) {
    this.setState({
      ...this.state,
      [name]: event.target.value
    });
  }

  handleEmailChange(event) {
    this.setState({
      email: event.target.value
    }, () => {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      this.setState({
        validateEmail: emailRegex.test(this.state.email)
      });
    });
  }

  login() {
    this.setState({isLoading: true});
    fetch(API_LINK+'/login', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        localStorage.setItem('user', JSON.stringify(json.user));
        this.setState({isLoading: false});
        this.props.onLogin();
        this.props.navigate('/dashboard/home');
      }
      else
      {
        this.setState({
          isLoading: false,
          alertText: json.message
        });
      }
    })
  }

  render() {
    const s = this.state;

    return(
      <div className="page-auth" style={{height: window.innerHeight}}>
        <header className="py-3">
          <Container>
            <Row>
              <Col md="6">
                <div>
                  <a href="http://172.20.10.2:8000/">
                    <img src={ require('../../../assets/logo-rtl-black.png') } width="100px" />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </header>
        <main>
          <Container>
            <Row>
              <Col md="4" className="offset-md-4">
                <div className="login-card bg-white p-3">
                  <h4 className="text-center fw-bold mb-4">تسجيل الدخول</h4>
                  <div className="mb-3">
                    <Label>البريد الإلكتروني: </Label>
                    <DefaultInput 
                      bsSize={'lg'} 
                      type="email" 
                      value={s.email} 
                      onChange={(event) => this.handleEmailChange(event)} 
                    />
                  </div>
                  <div className="mb-3">
                    <Label>كلمة المرور: </Label>
                    <PasswordInput 
                      bsSize={'lg'} 
                      value={s.password} 
                      onChange={(event) => this.handleChange('password', event)} 
                    />
                  </div>
                  <div className="mb-3">
                    <DefaultButton 
                      size={'lg'} 
                      block 
                      type="submit" 
                      color="dark" 
                      disabled={!s.email || !s.password || s.password.length < 8 || !s.validateEmail || s.isLoading}
                      onClick={() => this.login()}
                      isLoading={s.isLoading}
                    >
                      دخول
                    </DefaultButton>
                  </div>
                  {s.alertText &&
                    <Alert className="bg-danger text-light border-danger rounded-0">{ s.alertText }</Alert>
                  }
                </div>
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    );
  }
}

export default withRouter(LoginPage);
import React, { useState } from "react";
import { Input } from "reactstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import propTypes from 'prop-types';
import "./default.css"

export const PasswordInput = (props) => {
  const [ inputType, setInputType ] = useState('password');

  return(
    <div className="bliz-form-control-container">
      <Input {...props} type={inputType} className="bliz-form-control" bsSize={props.size ? props.size : 'lg'} />
      {inputType === 'password' ?
        <AiOutlineEye onClick={() => setInputType('text')} className="bliz-form-control-eye" />
      :
        <AiOutlineEyeInvisible onClick={() => setInputType('password')} className="bliz-form-control-eye" />
      }
    </div>
  );
}

PasswordInput.propTypes = {
  size: propTypes.string
}
import React, { Component } from "react";
import { Card, CardBody, Container, Table } from "reactstrap";
import { withRouter } from "../../../components/Router/withRouter";
import { API_LINK } from "../../../utils/constants";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { DefaultButton } from "../../../components/Buttons/default";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaClock } from "react-icons/fa6";
import { StatisticsCard } from "../../../components/Cards/Statistics";

class LogVisitorPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'links',

      visitorLinks: null,
      visitorEvents: null,
      visitorDelay: 0,
      visitorLoading: true
    }
  }

  componentDidMount() {
    this.getVisitorData();
  }

  getVisitorData() {
    this.setState({visitorLoading: true});
    
    fetch(API_LINK+'/logs/visitor/'+this.props.params.ip, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        visitorLinks: json.visites,
        visitorEvents: json.events,
        visitorDelay: json.delay,
        visitorLoading: false
      });
    })
  }

  render() {
    const s = this.state;
    
    return(
      <Container>
        <AdminBodyTitle>سجل آخر 100 تسجيل</AdminBodyTitle>

        <div>
          <StatisticsCard 
            isLoading={s.visitorLoading}
            title="مدة الزيارة"
            value={s.visitorDelay+' ثانية'}
            icon={<FaClock className="home-card-statistics-icon float-end mt-4 me-3" size={50} />}
          />
        </div>

        <div className="mb-3">
          <DefaultButton
            className={'me-3 rounded-pill'}
            outline={s.activeTab !== 'links'}
            color={'dark'}
            size={'md'}
            onClick={() => this.setState({activeTab: 'links'})}
          >
            الزيارات
          </DefaultButton>
          <DefaultButton
            className={'rounded-pill'}
            outline={s.activeTab !== 'events'}
            color={'dark'}
            size={'md'}
            onClick={() => this.setState({activeTab: 'events'})}
          >
            الأحداث
          </DefaultButton>
        </div>

        <Card>
          <CardBody>
            {s.activeTab === 'events' ?
              <Table responsive className="table-bliz bg-light">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>عنوان النافذة</th>
                    <th>الحدث</th>
                    <th>عنوان الشبكة</th>
                    <th>نوع الجهاز</th>
                    <th>المتصفح</th>
                    <th>نظام التشغيل</th>
                    <th>موديل الجهاز</th>
                    <th>التاريخ</th>
                  </tr>
                </thead>
                <tbody>
                {s.visitorLoading ?
                    <tr>
                      <td colSpan={10} align="center">
                        <AiOutlineLoading3Quarters className="animation-spin" />
                      </td>
                    </tr>
                  :
                    !s.visitorEvents || s.visitorEvents.length <= 0 ?
                      <tr>
                        <td colSpan={10} align="center">
                          لا توجد بيانات
                        </td>
                      </tr>
                    :
                      s.visitorEvents.map((data, key) => {
                        return(
                          <tr key={key}>
                            <td>{data.id}</td>
                            <td>{data.window_title}</td>
                            <td>{data.name}</td>
                            <td>{data.ip}</td>
                            <td title={data.device_resolutions}>{data.device_resolutions?.split('x')[0] <= 600 ? <span className="text-warning">جوال</span> : <span className="text-info">كمبيوتر</span>}</td>
                            <td>{data.browser_name}</td>
                            <td>{data.os}</td>
                            <td>{data.device_model}</td>
                            <td>{data.created_at.split('T')[0]}</td>
                          </tr>
                        );
                      })
                  }
                </tbody>
              </Table>
            :
              <Table responsive className="table-bliz bg-light">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>الدولة</th>
                    <th>من</th>
                    <th>إلى</th>
                    <th>عنوان الشبكة</th>
                    <th>نوع الجهاز</th>
                    <th>المتصفح</th>
                    <th>نظام التشغيل</th>
                    <th>موديل الجهاز</th>
                    <th>التاريخ</th>
                  </tr>
                </thead>
                <tbody>
                {s.visitorLoading ?
                    <tr>
                      <td colSpan={10} align="center">
                        <AiOutlineLoading3Quarters className="animation-spin" />
                      </td>
                    </tr>
                  :
                    !s.visitorLinks || s.visitorLinks.length <= 0 ?
                      <tr>
                        <td colSpan={10} align="center">
                          لا توجد بيانات
                        </td>
                      </tr>
                    :
                      s.visitorLinks.map((data, key) => {
                        return(
                          <tr key={key}>
                            <td>{data.id}</td>
                            <td>{data.country}</td>
                            <td title={data.from}>{data.from?.substr(0, 20)}...</td>
                            <td title={data.to}>{data.to?.substr(0, 20)}..</td>
                            <td>{data.ip}</td>
                            <td title={data.device_resolutions}>{data.device_resolutions?.split('x')[0] <= 600 ? <span className="text-warning">جوال</span> : <span className="text-info">كمبيوتر</span>}</td>
                            <td>{data.browser_name}</td>
                            <td>{data.os}</td>
                            <td>{data.device_model}</td>
                            <td>{data.created_at.split('T')[0]}</td>
                          </tr>
                        );
                      })
                  }
                </tbody>
              </Table>
            }
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default withRouter(LogVisitorPage);
import React, { Component } from "react";
import { Card, CardBody, Col, Container, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { AdminBodyTitle } from "../../components/Typography/Titles";
import { API_LINK, MAIN_LINK } from "../../utils/constants";
import { AiFillEdit, AiOutlineEye, AiOutlineLoading3Quarters, AiOutlinePlus } from "react-icons/ai";
import { DefaultButton } from "../../components/Buttons/default";
import { DefaultModal } from "../../components/Modals/Default";
import { DefaultInput } from "../../components/Forms/Inputs/default";
import { DefaultSelect } from "../../components/Forms/Inputs/Select";
import { BottomLeftAlert } from "../../components/Alerts";

class PackagePricingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      packagesData: null,
      packagesLoading: true,

      languagesData: null,
      languagesLoading: true,

      categoriesData: null,
      categoriesLoading: true,

      addModalShown: false,
      addLoading: false,
      addData: null,
      isEdit: false,
      editLoading: null,

      alertText: null
    }
  }

  componentDidMount() {
    this.getPackages();
  }

  getLanguages() {
    fetch(API_LINK+'/languages', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        languagesData: json.languages,
        languagesLoading: false
      }, () => {
        this.getCategories();
      });
    })
  }

  getCategories() {
    this.setState({categoriesLoading: true});
    
    fetch(API_LINK+'/package/categories', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        categoriesData: json.categories,
        categoriesLoading: false
      });
    })
  }

  getPackages() {
    this.setState({packagesLoading: true});
    
    fetch(API_LINK+'/packages', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        packagesData: json.packages,
        packagesLoading: false
      }, () => {
        this.getLanguages();
      });
    })
  }

  storePackage() {
    this.setState({addLoading: true});
    const LINK = this.state.isEdit ? API_LINK+'/package/update' : API_LINK+'/package/store';

    fetch(LINK, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        id: this.state.addData?.id,
        name: this.state.addData?.name,
        category_id: this.state.addData?.category_id,
        description: this.state.addData?.description,
        price: this.state.addData?.price,
        lang: this.state.addData?.lang,
        custom_domaine: this.state.addData?.custom_domaine,
        free_domaine: this.state.addData?.free_domaine,
        remove_ads: this.state.addData?.remove_ads,
        free_ssl: this.state.addData?.free_ssl,
        bandwidth: this.state.addData?.bandwidth,
        storage_space: this.state.addData?.storage_space,
        social_media_control: this.state.addData?.social_media_control,
        accept_payments: this.state.addData?.accept_payments,
        marketing_tools: this.state.addData?.marketing_tools,
        ai_helper: this.state.addData?.ai_helper
      })
    })
    .then((response) => response.json())
    .then((json) => {
      console.log(json);
      if(json.success)
      {
        this.setState({
          addData: null,
          addModalShown: false,
          addLoading: false
        }, () => {
          this.getPackages();
        });
      }
      else
      {
        this.setState({
          addModalShown: false,
          addLoading: false,
          alertText: json.message
        });
      }
    })
  }

  handleChange(event) {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      addData: {
        ...prevState.addData,
        [name]: value
      }
    }));
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <Row className="align-items-center">
          <Col xs="6">
            <AdminBodyTitle>أسعار العروض</AdminBodyTitle>
          </Col>
          <Col xs="6" className="text-end">
            <DefaultButton size={'md'} color={'success'} onClick={() => this.setState({addModalShown: true})}>
              <AiOutlinePlus /> إضافة باقة
            </DefaultButton>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <Table responsive className="table-bliz bg-light">
              <thead>
                <tr>
                  <th>#</th>
                  <th>الإسم</th>
                  <th>الوصف</th>
                  <th>التصنيف</th>
                  <th>السعر</th>
                  <th>خيارات</th>
                </tr>
              </thead>
              <tbody>
              {s.packagesLoading ?
                <tr>
                  <td colSpan={'13'} align="center">
                    <AiOutlineLoading3Quarters className="animation-spin" />
                  </td>
                </tr>
              :
                !s.packagesData || s.packagesData.length <= 0 ?
                  <tr>
                    <td colSpan={'13'} align="center">
                      لا توجد بيانات
                    </td>
                  </tr>
                :
                  s.packagesData.map((data, key) => {
                    return(
                      <tr key={key}>
                        <td>{ data.id }</td>
                        <td>{ data.name }</td>
                        <td>{ data.description }</td>
                        <td>{ data.category.name }</td>
                        <td>{ data.price } {data.category.country.currency_symbol}</td>
                        <td>
                          <a href={ MAIN_LINK+'/pricing' } target="_blank">
                            <DefaultButton className="btn-xs" color={'info'}>
                              <AiOutlineEye />
                            </DefaultButton>
                          </a>
                          {' '}
                          <DefaultButton className="btn-xs" color={'success'} onClick={() => this.setState({addData: data, isEdit: true, addModalShown: true})}>
                            <AiFillEdit />
                          </DefaultButton>
                        </td>
                      </tr>
                    );
                  })
              }
              </tbody>
            </Table>
          </CardBody>
        </Card>

        {/* alert */}
        {s.alertText &&
          <BottomLeftAlert color={'danger'}>{s.alertText}</BottomLeftAlert>
        }

        {/* add modal */}
        <DefaultModal 
          title={'إضافة باقة'}
          isOpen={s.addModalShown}
          toggle={() => this.setState({addModalShown: null})}
          onConfirm={() => this.storePackage()}
          confirmationText={'حفظ'}
          cancelText={'غلق'}
          isLoading={s.addLoading}
        >
          <FormGroup>
            <Label>الإسم: </Label>
            <DefaultInput 
              value={s.addData?.name}
              name={'name'}
              onChange={(event) => this.handleChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>التصنيف: </Label>
            <DefaultSelect onChange={(event) => this.handleChange(event)} name={'category_id'} value={s.addData?.category_id}>
              <option value="">اختر التصنيف</option>
              {s.categoriesData && s.categoriesData.length > 0 &&
                s.categoriesData.map((data, key) => {
                  return(
                    <option value={data.id} key={key}>{data.name} ({data.country?.name})</option>
                  );
                })
              }
            </DefaultSelect>
          </FormGroup>
          <FormGroup>
            <Label>الوصف: </Label>
            <DefaultInput 
              value={s.addData?.description}
              name={'description'}
              onChange={(event) => this.handleChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>السعر: </Label>
            <DefaultInput 
              value={s.addData?.price}
              name={'price'}
              onChange={(event) => this.handleChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>اللغة: </Label>
            <DefaultSelect onChange={(event) => this.handleChange(event)} name={'lang'} value={s.addData?.lang}>
              <option value="">اختر اللغة</option>
              {s.languagesData && s.languagesData.length > 0 &&
                s.languagesData.map((data, key) => {
                  return(
                    <option value={data.short} key={key}>{data.name}</option>
                  );
                })
              }
            </DefaultSelect>
          </FormGroup>
          <Row>
            <Col xs="6">
              <FormGroup>
                <Input 
                  type={'checkbox'} 
                  checked={s.addData?.custom_domaine === '1'}
                  name="custom_domaine"
                  value={s.addData?.custom_domaine === '1' ? '0' : '1'}
                  onChange={(event) => this.handleChange(event)}
                />
                {' '}
                <Label>دومين خاص</Label>
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup>
                <Input 
                  type={'checkbox'} 
                  checked={s.addData?.free_domaine === '1'}
                  name="free_domaine"
                  value={s.addData?.free_domaine === '1' ? '0' : '1'}
                  onChange={(event) => this.handleChange(event)}
                />
                {' '}
                <Label>دومين مجاني</Label>
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup>
                <Input 
                  type={'checkbox'} 
                  checked={s.addData?.remove_ads === '1'}
                  name="remove_ads"
                  value={s.addData?.remove_ads === '1' ? '0' : '1'}
                  onChange={(event) => this.handleChange(event)}
                />
                {' '}
                <Label>حذف الإعلانات</Label>
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup>
                <Input 
                  type={'checkbox'} 
                  checked={s.addData?.free_ssl === '1'}
                  name="free_ssl"
                  value={s.addData?.free_ssl === '1' ? '0' : '1'}
                  onChange={(event) => this.handleChange(event)}
                />
                {' '}
                <Label>شهادة SSL</Label>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label>Bandwidh: </Label>
            <DefaultInput 
              type={'number'} 
              value={s.addData?.bandwidth}
              name="bandwidth"
              onChange={(event) => this.handleChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>مساحة التخزين: </Label>
            <DefaultInput 
              type={'number'} 
              value={s.addData?.storage_space}
              name="storage_space"
              onChange={(event) => this.handleChange(event)}
            />
          </FormGroup>
          <Row>
            <Col xs="6">
              <FormGroup>
                <Input 
                  type={'checkbox'} 
                  checked={s.addData?.social_media_control === '1'}
                  name="social_media_control"
                  value={s.addData?.social_media_control === '1' ? '0' : '1'}
                  onChange={(event) => this.handleChange(event)}
                />
                {' '}
                <Label>النشر على مواقع التواصل</Label>
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup>
                <Input 
                  type={'checkbox'} 
                  checked={s.addData?.accept_payments === '1'}
                  name="accept_payments"
                  value={s.addData?.accept_payments === '1' ? '0' : '1'}
                  onChange={(event) => this.handleChange(event)}
                />
                {' '}
                <Label>بوابات الدفع</Label>
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup>
                <Input 
                  type={'checkbox'} 
                  checked={s.addData?.marketing_tools === '1'}
                  name="marketing_tools"
                  value={s.addData?.marketing_tools === '1' ? '0' : '1'}
                  onChange={(event) => this.handleChange(event)}
                />
                {' '}
                <Label>أدوات التسويق</Label>
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup>
                <Input 
                  type={'checkbox'} 
                  checked={s.addData?.ai_helper === '1'}
                  name="ai_helper"
                  value={s.addData?.ai_helper === '1' ? '0' : '1'}
                  onChange={(event) => this.handleChange(event)}
                />
                {' '}
                <Label>مساعد الذكاء الإصطناعي</Label>
              </FormGroup>
            </Col>
          </Row>
        </DefaultModal>
      </Container>
    );
  }
}

export default PackagePricingPage;
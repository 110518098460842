import React, { Component } from "react";
import { Card, CardBody, CardFooter, Col, Container, FormGroup, Label, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";
import { AiFillEdit, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { AdminBodyTitle } from "../../components/Typography/Titles";
import { DefaultInput } from "../../components/Forms/Inputs/default";
import { DefaultButton } from "../../components/Buttons/default";
import { DefaultSelect } from "../../components/Forms/Inputs/Select";
import { API_LINK } from "../../utils/constants";
import { BottomLeftAlert } from "../../components/Alerts";
import { DefaultModal } from "../../components/Modals/Default";

class TemplateCategoriesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categoriesData: null,
      categoriesLoading: true,

      languagesData: null,
      languagesLoading: true,

      name: null,
      slug: null,
      image: null,
      lang: null,
      min_pages: null,

      addLoading: false,

      alertText: null,

      updateLoading: null,
      categoryToEdit: null
    }
  }

  componentDidMount() {
    this.getLanguages();
  }

  getLanguages() {
    fetch(API_LINK+'/languages', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        languagesData: json.languages,
        languagesLoading: false
      }, () => {
        this.getCategories();
      });
    })
  }

  getCategories() {
    this.setState({categoriesLoading: true});
    
    fetch(API_LINK+'/template/categories', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        categoriesData: json.categories,
        categoriesLoading: false
      });
    })
  }

  storeCategory() {
    this.setState({addLoading: true});

    const formData = new FormData();
    formData.append('image', this.state.image);
    formData.append('name', this.state.name);
    formData.append('slug', this.state.slug);
    formData.append('lang', this.state.lang);
    formData.append('min_pages', this.state.min_pages);

    fetch(API_LINK+'/template/category/store', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: formData
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          addLoading: false,
          name: '',
          lang: '',
          image: '',
          min_pages: '',
        }, () => {
          this.getCategories();
        });
      } else {
        this.setState({
          alertText: json.message
        });
      }
    })
  }

  updateCategory() {
    this.setState({updateLoading: true});

    const formData = new FormData();
    formData.append('id', this.state.categoryToEdit.id);
    formData.append('name', this.state.categoryToEdit.name);
    formData.append('slug', this.state.categoryToEdit.slug);
    formData.append('lang', this.state.categoryToEdit.lang);
    formData.append('min_pages', this.state.categoryToEdit.min_pages);
    if(this.state.image)
    {
      formData.append('image', this.state.image);
    }

    fetch(API_LINK+'/template/category/update', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: formData
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          updateLoading: false,
          categoryToEdit: null
        }, () => {
          this.getCategories();
        });
      } else {
        this.setState({
          alertText: json.message
        });
      }
    })
  }

  handleChange(name, event) {
    this.setState({
      ...this.state,
      [name]: event.target.value
    });
  }

  handleFileChange(event) {
    this.setState({ 
      image: event.target.files[0] 
    });
  }

  handleEditChange(name, event) {
    this.setState((prevState) => ({
      categoryToEdit: {
        ...prevState.categoryToEdit,
        [name]: event.target.value,
      },
    }));
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <AdminBodyTitle>تصنيفات القوالب</AdminBodyTitle>
        <Row>
          <Col md="4">
            <Card>
              {s.languagesLoading ?
                <CardBody className="text-center">
                  <AiOutlineLoading3Quarters className="animation-spin h2" />
                </CardBody>
              :
                <CardBody>
                  <h4 className="fw-bold mb-4">إضافة تصنيف</h4>
                  <FormGroup>
                    <Label>إسم التصنيف: </Label>
                    <DefaultInput 
                      value={s.name}
                      onChange={(event) => this.handleChange('name', event)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Slug: </Label>
                    <DefaultInput 
                      value={s.slug}
                      onChange={(event) => this.handleChange('slug', event)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>صورة التصنيف: </Label>
                    <DefaultInput 
                      type="file" 
                      onChange={(event) => this.handleFileChange(event)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>اللغة: </Label>
                    <DefaultSelect value={s.lang} onChange={(event) => this.handleChange('lang', event)}>
                      <option value="">اختر اللغة</option>
                      {s.languagesData && s.languagesData.length > 0 &&
                        s.languagesData.map((data, key) => {
                          return(
                            <option value={data.short} key={key}>{data.name}</option>
                          );
                        })
                      }
                    </DefaultSelect>
                  </FormGroup>
                  <FormGroup>
                    <Label>أقل عدد صفحات: </Label>
                    <DefaultInput 
                      type="number" 
                      value={s.min_pages}
                      onChange={(event) => this.handleChange('min_pages', event)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <DefaultButton 
                      color={'dark'} 
                      block
                      isLoading={s.addLoading}
                      disabled={s.addLoading || !s.name || !s.image || !s.lang || !s.min_pages}
                      onClick={() => this.storeCategory()}
                    >
                      حفظ
                    </DefaultButton>
                  </FormGroup>
                </CardBody>
              }
            </Card>
          </Col>
          <Col md="8">
            <Card>
              <CardBody>
                <h4 className="fw-bold mb-4">كل التصنيفات</h4>
                <Table responsive className="table-bliz bg-light">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>الإسم</th>
                      <th>Slug</th>
                      <th>اللغة</th>
                      <th>عدد الصفحات الأدنى</th>
                      <th>خيارات</th>
                    </tr>
                  </thead>
                  <tbody>
                    {s.categoriesLoading ?
                      <tr>
                        <td colSpan={'6'} align="center">
                          <AiOutlineLoading3Quarters className="animation-spin" />
                        </td>
                      </tr>
                    :
                      !s.categoriesData || s.categoriesData.length <= 0 ?
                        <tr>
                          <td colSpan={'6'} align="center">
                            لا توجد بيانات
                          </td>
                        </tr>
                      :
                        s.categoriesData.map((data, key) => {
                          return(
                            <tr key={key}>
                              <td>{ data.id }</td>
                              <td>{ data.name }</td>
                              <td>{ data.slug }</td>
                              <td>{ data.lang }</td>
                              <td>{ data.min_pages }</td>
                              <td>
                                <DefaultButton className="btn-xs" color={'success'} onClick={() => this.setState({categoryToEdit: data})}>
                                  <AiFillEdit />
                                </DefaultButton>
                              </td>
                            </tr>
                          );
                        })
                    }
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* alert */}
        {s.alertText &&
          <BottomLeftAlert color={'danger'}>{s.alertText}</BottomLeftAlert>
        }

        {/* edit modal */}
        <DefaultModal
          title={'تعديل التصنيف'} 
          isOpen={s.categoryToEdit} 
          toggle={() => this.setState({categoryToEdit: null})}
          onConfirm={() => this.updateCategory()}
          confirmationText={'حفظ'}
          cancelText={'غلق'}
          isLoading={s.updateLoading}
        >
          <FormGroup>
            <Label>إسم التصنيف: </Label>
            <DefaultInput 
              value={s.categoryToEdit?.name}
              onChange={(event) => this.handleEditChange('name', event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Slug: </Label>
            <DefaultInput 
              value={s.categoryToEdit?.slug}
              onChange={(event) => this.handleEditChange('slug', event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>صورة التصنيف: </Label>
            <DefaultInput 
              type="file" 
              onChange={(event) => this.handleFileChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>اللغة: </Label>
            <DefaultSelect value={s.categoryToEdit?.lang} onChange={(event) => this.handleEditChange('lang', event)}>
              {s.languagesData && s.languagesData.length > 0 &&
                s.languagesData.map((data, key) => {
                  return(
                    <option value={data.code} key={key}>{data.name} ({data.code})</option>
                  );
                })
              }
            </DefaultSelect>
          </FormGroup>
          <FormGroup>
            <Label>أقل عدد صفحات: </Label>
            <DefaultInput 
              type="number" 
              value={s.categoryToEdit?.min_pages}
              onChange={(event) => this.handleEditChange('min_pages', event)}
            />
          </FormGroup>
        </DefaultModal>
      </Container>
    );
  }
}

export default TemplateCategoriesPage;
import React, { Component } from "react";
import { Card, CardBody, Container, Table } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { API_LINK } from "../../../utils/constants";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { DefaultButton } from "../../../components/Buttons/default";
import { Link } from "react-router-dom";

class LogChatGPTPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gptData: null,
      gptLoading: true,

      contentToShow: null
    }
  }

  componentDidMount() {
    this.getGPT();
  }

  getGPT() {
    this.setState({gptLoading: true});
    
    fetch(API_LINK+'/logs/chat_gpt', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        gptData: json.gpt,
        gptLoading: false
      });
    })
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <AdminBodyTitle>سجل آخر 100 طلب</AdminBodyTitle>

        <Card>
          <CardBody>
            <Table responsive className="table-bliz bg-light">
              <thead>
                <tr>
                  <th>#</th>
                  <th>المستخدم</th>
                  <th>نوع الوحدة</th>
                  <th>الرد</th>
                  <th>التاريخ</th>
                </tr>
              </thead>
              <tbody>
              {s.gptLoading ?
                  <tr>
                    <td colSpan={10} align="center">
                      <AiOutlineLoading3Quarters className="animation-spin" />
                    </td>
                  </tr>
                :
                  !s.gptData || s.gptData.length <= 0 ?
                    <tr>
                      <td colSpan={10} align="center">
                        لا توجد بيانات
                      </td>
                    </tr>
                  :
                    s.gptData.map((data, key) => {
                      return(
                        <tr key={key}>
                          <td>{data.id}</td>
                          <td><Link to={"/dashboard/user/"+data.user.id}>{data.user.username}</Link></td>
                          <td>{data.template_section_type?.name || ''}</td>
                          <td>
                            <DefaultButton
                               onClick={() => this.setState({contentToShow: data.response})}
                               size={'sm'}
                               color={'dark'}
                            >
                              عرض الوحدة
                            </DefaultButton>
                          </td>
                          <td>{data.created_at.split('T')[0]}</td>
                        </tr>
                      );
                    })
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default LogChatGPTPage;
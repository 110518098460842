import React, { Component, createRef } from "react";
import { Button, Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import { AiOutlineCamera } from 'react-icons/ai';
import './styles.css';
import { GeneralSettingsForm } from "../../components/Profile/GeneralSettings";
import { SecuritySettingsForm } from "../../components/Profile/SecuritySettings";
import { EmailSettingsForm } from "../../components/Profile/EmailSettings";
import { BottomLeftAlert } from "../../components/Alerts";

class ProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstname: null,
      lastname: null,
      email: null,
      profile_picture: null,
      profile_picture_src: null,

      alertText: null,
      alertType: 'danger'
    }
  }

  componentDidMount() {
    this.setUserData();
  }

  fileChange = event => {
    event.preventDefault();

    this.setState({ file: event.target.files[0] });

    let imageFile = event.target.files[0];

    if (imageFile) {
      const localImageUrl = URL.createObjectURL(imageFile);
      this.setState({profile_picture_src: localImageUrl})
    }
  };

  setUserData() {
    const user = localStorage.getItem('user');

    this.setState({
      firstname: JSON.parse(user).firstname,
      lastname: JSON.parse(user).lastname,
      email: JSON.parse(user).email,
      email_verified_at: JSON.parse(user).email_verified_at,
      profile_picture: JSON.parse(user).profile_picture_path
    });
  }

  render() {
    const s = this.state;

    return(
      <Container fluid>
        <Row>
          <Col md="8">
            <GeneralSettingsForm 
              setAlert={(content, type) => this.setState({alertText: content, alertType: type})}
              setUserData={() => this.setUserData()}
            />

            <EmailSettingsForm 
              setAlert={(content, type) => this.setState({alertText: content, alertType: type})}
              setUserData={() => this.setUserData()}
            />
          </Col>
          <Col md="4">
            <Card className="rounded-0 border-0 mb-3">
              <CardBody>
                <Label>الصورة الشخصية: </Label>
                <div className="account-img-selection border border-dark p-3" onClick={() => document.getElementById('pimage').click() }>
                  <img src={ s.profile_picture_src ? s.profile_picture_src : require('../../assets/brand-black.png') } id="pimageView" width="100%" />
                  <div className="account-img-icon-container">
                    <AiOutlineCamera className="account-img-icon" />
                  </div>
                </div>
                <Input 
                  type="file" 
                  id="pimage"
                  name="image" 
                  onChange={event => this.fileChange(event)}
                  accept="image/*" 
                  hidden
                />
                {s.profile_picture_src &&
                  <Button type="submit" size="lg" color="dark" className="mt-3" block>Save</Button>
                }
              </CardBody>
            </Card>

            <SecuritySettingsForm 
              setAlert={(content, type) => this.setState({alertText: content, alertType: type})}
              setUserData={() => this.setUserData()}
            />
          </Col>
        </Row>

        {s.alertText &&
          <BottomLeftAlert color={s.alertType} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }
      </Container>
    );
  }
}

export default ProfilePage;
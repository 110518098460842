import React, { Component } from "react";
import { Alert, Button, Card, CardBody, Container, FormGroup, Label, Table } from "reactstrap";
import { NotificationCard } from "../../components/Cards/Notification";
import { AdminBodyTitle } from "../../components/Typography/Titles";
import { API_LINK, MAIN_LINK } from "../../utils/constants";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { DefaultModal } from "../../components/Modals/Default";
import { FaRegEnvelope, FaRegEnvelopeOpen } from "react-icons/fa";
import { DefaultInput } from "../../components/Forms/Inputs/default";

class NotificationsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quotesData: null,
      quotesLoading: true,

      selectedQuote: null
    }
  }

  componentDidMount() {
    this.getQuotes();
  }

  getQuotes() {
    this.setState({quotesLoading: true});
    
    fetch(API_LINK+'/quotes', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        quotesData: json.quotes,
        quotesLoading: false
      });
    })
  }

  render() {
    const s = this.state;

    return(
      <Container fluid>
        <AdminBodyTitle>الطلبات</AdminBodyTitle>
        {s.quotesLoading ?
          <div className="text-center">
            <AiOutlineLoading3Quarters className="animation-spin h1" />
          </div>
        :
          s.quotesData?.data?.length > 0 ?
          <Card>
            <CardBody>
              <Table responsive className="table-bliz bg-light">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>الإسم الكامل</th>
                    <th>البريد الإلكتروني</th>
                    <th>عنوان الشبكة</th>
                    <th>رقم الهاتف</th>
                    <th>خيارات</th>
                  </tr>
                </thead>
                <tbody>
                  {s.quotesData?.data.map((data, key) => {
                    return(
                      <tr key={key}>
                        <td>{ data.id }</td>
                        <td>{ data.firstname+' '+data.lastname }</td>
                        <td>{ data.email }</td>
                        <td>{ data.ip }</td>
                        <td><span dir="ltr">{ data.country_code+data.phone_number }</span></td>
                        <td>
                          <Button className="btn btn-xs btn-success" onClick={() => this.setState({selectedQuote: data})}>
                            {data.status === 0 ?
                              <FaRegEnvelope />
                              :
                              <FaRegEnvelopeOpen />
                            }
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
          :
            <Alert color="info">لا توجد طلبات</Alert>
        }

        <DefaultModal
          isOpen={s.selectedQuote ? true : false}
          title={'تفاصيل الطلب'}
          cancelText={'غلق'}
          toggle={() => this.setState({selectedQuote: null})}
        >
          <FormGroup>
            <Label>الإسم الكامل: </Label>
            <DefaultInput readOnly value={s.selectedQuote?.firstname+' '+s.selectedQuote?.lastname} />
          </FormGroup>
          <FormGroup>
            <Label>رقم الهاتف: </Label>
            <DefaultInput readOnly type={'tel'} value={s.selectedQuote?.country_code+s.selectedQuote?.phone_number} />
          </FormGroup>
          <FormGroup>
            <Label>إسم الشركة: </Label>
            <DefaultInput readOnly value={s.selectedQuote?.company_name} />
          </FormGroup>
          <FormGroup>
            <Label>الرسالة: </Label>
            <DefaultInput type={'textarea'} readOnly value={s.selectedQuote?.description} />
          </FormGroup>
          <FormGroup>
            <Label>البريد الإلكتروني</Label>
            <DefaultInput readOnly value={s.selectedQuote?.email} />
          </FormGroup>
          <FormGroup>
            <Label>عنوان الشبكة: </Label>
            <DefaultInput readOnly value={s.selectedQuote?.ip} />
          </FormGroup>
          <FormGroup>
            <Label>تاريخ الرسالة: </Label>
            <DefaultInput readOnly value={s.selectedQuote?.created_at} />
          </FormGroup>
          {s.selectedQuote?.attachment &&
            <FormGroup>
              <Label>ملف مرفق: </Label>
                <a href={MAIN_LINK+s.selectedQuote.attachment} target="_blank">تحميل الملف</a>
            </FormGroup>
          }
        </DefaultModal>
      </Container>
    );
  }
}

export default NotificationsPage;
import React, { Component } from "react";
import { Card, CardBody, Col, Container, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { AiOutlineLoading3Quarters, AiFillEdit } from 'react-icons/ai';
import { AdminBodyTitle } from "../../components/Typography/Titles";
import { DefaultInput } from "../../components/Forms/Inputs/default";
import { DefaultButton } from "../../components/Buttons/default";
import { API_LINK, MAIN_LINK } from "../../utils/constants";
import { BottomLeftAlert } from "../../components/Alerts";
import { DefaultSelect } from "../../components/Forms/Inputs/Select";
import { DefaultModal } from "../../components/Modals/Default";

class CountriesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countriesData: null,
      countriesLoading: true,

      addLoading: false,

      name: null,
      name_en: null,
      country_code: null,
      flag: null,
      currency_code: null,
      currency_symbol: null,
      status: null,
      readonly: 'on',

      updateLoading: false,
      countryToEdit: null,

      alertText: null
    }
  }

  componentDidMount() {
    this.getCountries();
  }

  getCountries() {
    this.setState({countriesLoading: true});
    fetch(API_LINK+'/countries', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        countriesData: json.countries,
        countriesLoading: false
      });
    })
  }

  storeCountry() {
    this.setState({addLoading: true});

    const formData = new FormData();
    formData.append('flag', this.state.flag);
    formData.append('name', this.state.name);
    formData.append('name_en', this.state.name_en);
    formData.append('country_code', this.state.country_code);
    formData.append('currency_code', this.state.currency_code);
    formData.append('currency_symbol', this.state.currency_symbol);
    formData.append('status', this.state.status);
    formData.append('readonly', this.state.readonly === 'on' ? 1 : 0);

    fetch(API_LINK+'/country/store', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: formData
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          addLoading: false,
          name: '',
          country_code: '',
          flag: '',
          currency_code: '',
          status: ''
        }, () => {
          this.getCountries();
        });
      } else {
        this.setState({
          alertText: json.message
        });
      }
    })
  }

  updateCountry() {
    this.setState({updateLoading: true});

    const formData = new FormData();
    formData.append('id', this.state.countryToEdit.id);
    formData.append('name', this.state.countryToEdit.name);
    formData.append('name_en', this.state.countryToEdit.name_en);
    formData.append('country_code', this.state.countryToEdit.country_code);
    formData.append('currency_code', this.state.countryToEdit.currency_code);
    formData.append('currency_symbol', this.state.countryToEdit.currency_symbol);
    formData.append('status', this.state.countryToEdit.status);
    formData.append('readonly', this.state.countryToEdit.readonly === 'on' ? 1 : 0);
    if(this.state.flag)
    {
      formData.append('flag', this.state.flag);
    }

    fetch(API_LINK+'/country/update', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: formData
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          updateLoading: false,
          countryToEdit: null
        }, () => {
          this.getCountries();
        });
      } else {
        this.setState({
          alertText: json.message
        });
      }
    })
  }

  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value
    });
  }

  handleFileChange(event) {
    this.setState({ 
      flag: event.target.files[0] 
    });
  }

  handleEditChange(event) {
    this.setState((prevState) => ({
      countryToEdit: {
        ...prevState.countryToEdit,
        [event.target.name]: event.target.value,
      },
    }));
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <AdminBodyTitle>الدول</AdminBodyTitle>
        <Row>
          <Col md="4">
            <Card>
              <CardBody>
                <h4>إضافة دولة</h4>
                <FormGroup>
                  <Label>إسم الدولة: </Label>
                  <DefaultInput 
                    name="name"
                    value={s.name}
                    onChange={(event) => this.handleChange(event)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>إسم الدولة (EN): </Label>
                  <DefaultInput 
                    name="name_en"
                    value={s.name_en}
                    onChange={(event) => this.handleChange(event)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>رمز الدولة: </Label>
                  <DefaultInput 
                    name="country_code"
                    value={s.country_code}
                    onChange={(event) => this.handleChange(event)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>علم الدولة: </Label>
                  <DefaultInput 
                    type={'file'} 
                    onChange={(event) => this.handleFileChange(event)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>عملة الدولة: </Label>
                  <DefaultInput 
                    name="currency_code"
                    value={s.currency_code}
                    onChange={(event) => this.handleChange(event)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>رمز العملة: </Label>
                  <DefaultInput 
                    name="currency_symbol"
                    value={s.currency_symbol}
                    onChange={(event) => this.handleChange(event)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>الحالة: </Label>
                  <DefaultSelect name="status" value={s.status} onChange={(event) => this.handleChange(event)}>
                    <option value="">اختر الحالة</option>
                    <option value="0">معطلة</option>
                    <option value="1">نشطة</option>
                  </DefaultSelect>
                </FormGroup>
                <FormGroup>
                  <Input 
                    name="readonly"
                    type="checkbox" 
                    checked={s.readonly} 
                    onChange={() => this.setState({readonly: s.readonly === 'on' ? null : 'on'})} 
                  />{' '}
                  قرائة فقط
                </FormGroup>
                <FormGroup>
                  <DefaultButton 
                    color={'dark'} 
                    block
                    disabled={s.addLoading || !s.name || !s.country_code || !s.flag || !s.currency_code || !s.status}
                    isLoading={s.addLoading}
                    onClick={() => this.storeCountry()}
                  >
                    حفظ
                  </DefaultButton>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
          <Col md="8">
            <Card>
              <CardBody>
                <h4>كل الدول</h4>
                <Table responsive className="table-bliz bg-light">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>الإسم</th>
                      <th>الإسم (EN)</th>
                      <th>رمز الدولة</th>
                      <th>علم الدولة</th>
                      <th>عملة الدولة</th>
                      <th>الحالة</th>
                      <th>خيارات</th>
                    </tr>
                  </thead>
                  <tbody>
                    {s.countriesLoading ?
                      <tr>
                        <td colSpan={8} align="center">
                          <AiOutlineLoading3Quarters className="animation-spin" />
                        </td>
                      </tr>
                    :
                      !s.countriesData || s.countriesData <= 0 ?
                        <tr>
                          <td colSpan={8} align="center">
                            لا توجد بيانات
                          </td>
                        </tr>
                      :
                        s.countriesData.map((data, key) => {
                          return(
                            <tr key={key}>
                              <td>{ data.id }</td>
                              <td>{ data.name }</td>
                              <td>{ data.name_en }</td>
                              <td><span dir="ltr">{ data.country_code }</span></td>
                              <td>
                                <img src={ MAIN_LINK+data.flag } width="30px" />
                              </td>
                              <td>{ data.currency_code }</td>
                              <td>
                                {data.status === 0 ? <span className="text-danger">معطلة</span> : <span className="text-success">نشطة</span>}
                                {data.readonly ? <span className="text-primary"> | قراءة فقط</span> : ''}
                              </td>
                              <td>
                                <DefaultButton className="btn-xs" color={'success'} onClick={() => this.setState({countryToEdit: data})}>
                                  <AiFillEdit />
                                </DefaultButton>
                              </td>
                            </tr>
                          );
                        })
                    }
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* alert */}
        {s.alertText &&
          <BottomLeftAlert color={'danger'}>{s.alertText}</BottomLeftAlert>
        }

        {/* edit modal */}
        <DefaultModal
          title={'تعديل الدولة'} 
          isOpen={s.countryToEdit} 
          toggle={() => this.setState({countryToEdit: null})}
          onConfirm={() => this.updateCountry()}
          confirmationText={'حفظ'}
          cancelText={'غلق'}
          isLoading={s.updateLoading}
        >
          <FormGroup>
            <Label>إسم الدولة: </Label>
            <DefaultInput 
              name="name"
              value={s.countryToEdit?.name}
              onChange={(event) => this.handleEditChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>إسم الدولة (EN): </Label>
            <DefaultInput 
              name="name_en"
              value={s.countryToEdit?.name_en}
              onChange={(event) => this.handleEditChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>رمز الدولة: </Label>
            <DefaultInput 
              name="country_code"
              value={s.countryToEdit?.country_code}
              onChange={(event) => this.handleEditChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>علم الدولة: </Label>
            <DefaultInput 
              type={'file'} 
              onChange={(event) => this.handleFileChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>عملة الدولة: </Label>
            <DefaultInput 
              name="currency_code"
              value={s.countryToEdit?.currency_code}
              onChange={(event) => this.handleEditChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>رمز العملة: </Label>
            <DefaultInput 
              name="currency_symbol"
              value={s.countryToEdit?.currency_symbol}
              onChange={(event) => this.handleEditChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>الحالة: </Label>
            <DefaultSelect name="status" value={s.countryToEdit?.status} onChange={(event) => this.handleEditChange(event)}>
              <option value="">اختر الحالة</option>
              <option value="0">معطلة</option>
              <option value="1">نشطة</option>
            </DefaultSelect>
          </FormGroup>
          <FormGroup>
            <Input 
              name="readonly"
              type="checkbox" 
              checked={s.countryToEdit?.readonly} 
              onChange={() => this.setState((prevState) => ({
                countryToEdit: {
                  ...prevState.countryToEdit,
                  readonly: s.readonly === 'on' ? null : 'on'
                },
              }))} 
            />{' '}
            قرائة فقط
          </FormGroup>
        </DefaultModal>
      </Container>
    );
  }
}

export default CountriesPage;
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

class NotFoundPage extends Component {
  render() {
    return(
      <div className="notfound-page text-center">
        <Row className="mt-5">
          <Col lg="3" md="4" className="mx-auto mt-5">
            <div className="notfound-page-logo mb-3">
              <img src={ require('../../assets/brand-black.png') } width="100%" />
            </div>
            <h3 className="fw-bold">Page Not found!</h3>
            <Link to="/login">Home</Link>
          </Col>
        </Row>
      </div>
    );
  }
}

export default NotFoundPage;
import React, { useEffect, useState } from "react";
import { Card, CardBody, FormGroup, Label } from "reactstrap";
import { DefaultButton } from "../Buttons/default";
import { DefaultInput } from "../Forms/Inputs/default";
import { API_LINK } from "../../utils/constants";

export const GeneralSettingsForm = (props) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));

    setData({
      firstname: user.firstname,
      lastname: user.lastname
    });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const update = () => {
    setLoading(true);
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/account/general/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        firstname: data.firstname,
        lastname: data.lastname
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        localStorage.setItem('user', JSON.stringify(json.user));
        setData({firstname: json.user.firstname, lastname: json.user.lastname});
        props.setUserData();
        props.setAlert(json.message, 'success');
      } else {
        props.setAlert(json.message, 'danger');
      }
      setLoading(false);
    })
  }

  return(
    <Card className="rounded-0 border-0 mb-3">
      <CardBody>
        <h4 className="fw-bold">إعدادات عامة</h4>
        <form>
          <FormGroup>
            <Label>الإسم: </Label>
            <DefaultInput 
              type="text" 
              name="firstname" 
              value={data.firstname} 
              onChange={(event) => handleInputChange(event)} 
            />
          </FormGroup>
          <FormGroup>
            <Label>اللقب: </Label>
            <DefaultInput 
              type="text" 
              name="lastname" 
              value={data.lastname} 
              onChange={(event) => handleInputChange(event)} 
            />
          </FormGroup>
          <FormGroup>
            <DefaultButton 
              color="dark" 
              block
              isLoading={loading}
              onClick={() => update()}
            >
              حفظ
            </DefaultButton>
          </FormGroup>
        </form>
      </CardBody>
    </Card>
  );
}
import React, { Component } from "react";
import { Card, CardBody, CardFooter, Col, Container, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { AiFillEdit, AiOutlineEye, AiOutlineLoading3Quarters } from "react-icons/ai";
import { API_LINK, MAIN_LINK } from "../../../utils/constants";
import { MdDelete } from "react-icons/md";
import { DefaultButton } from "../../../components/Buttons/default";
import { Link } from "react-router-dom";
import { ConfirmationModal } from "../../../components/Modals/Confirmation";

class BlogPostsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      postsData: null,
      postsLoading: true,
      currentPage: 1,

      postToDestroy: null,
      destroyLoading: false,

      alertText: null
    }
  }

  componentDidMount() {
    this.getPosts();
  }

  getPosts() {
    this.setState({postsLoading: true});
    
    fetch(API_LINK+'/blog/posts?page='+this.state.currentPage, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          postsData: json.posts,
          postsLoading: false
        });
      }
    })
  }

  destroy() {
    this.setState({destroyLoading: true});
    
    fetch(API_LINK+'/blog/post/destroy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        id: this.state.postToDestroy
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          postToDestroy: null,
          destroyLoading: false
        }, () => {
          this.getPosts();
        });
      }
    })
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <Row className="d-flex align-items-center">
          <Col md="6">
            <AdminBodyTitle>المقالات</AdminBodyTitle>
          </Col>
          <Col md="6" className="text-end">
            <Link to={'/dashboard/blog/post/add'}>
              <DefaultButton color={'dark'}>+ إضافة مقال</DefaultButton>
            </Link>
          </Col>
        </Row>

        <Card>
          <CardBody>
            <Table responsive className="table-bliz bg-light">
              <thead>
                <tr>
                  <th>#</th>
                  <th>العنوان</th>
                  <th>اللغة</th>
                  <th>عدد المشاهدات</th>
                  <th>تاريخ الإضافة</th>
                  <th>خيارات</th>
                </tr>
              </thead>
              <tbody>
                {s.postsLoading ?
                  <tr>
                    <td colSpan={6} align="center">
                      <AiOutlineLoading3Quarters className="animation-spin" />
                    </td>
                  </tr>
                :
                  s.postsData?.data?.length > 0 ?
                    s.postsData.data.map((data, key) => (
                      <tr key={key}>
                        <td>{data.id}</td>
                        <td>{data.title}</td>
                        <td>{data.lang}</td>
                        <td>{data.views}</td>
                        <td>{data.created_at.split('T')[0]}</td>
                        <td>
                          <a href={MAIN_LINK+'/blog/post/'+data.id} target="_blank">
                            <DefaultButton size={'xs'} color={'info'}>
                              <AiOutlineEye />
                            </DefaultButton>
                          </a>
                          {' '}
                          <Link to={'/dashboard/blog/post/edit/'+data.id}>
                            <DefaultButton size={'xs'} color={'success'}>
                              <AiFillEdit />
                            </DefaultButton>
                          </Link>
                          {' '}
                          <DefaultButton size={'xs'} color={'danger'} onClick={() => this.setState({postToDestroy: data.id})}>
                            <MdDelete />
                          </DefaultButton>
                        </td>
                      </tr>
                    ))
                  :
                    <tr>
                      <td colSpan={6} align="center">
                        لا توجد بيانات
                      </td>
                    </tr>
                }
              </tbody>
            </Table>
          </CardBody>

          {s.postsData?.data?.length > 0 &&
            <CardFooter>
              <Pagination>
                <PaginationItem>
                  <PaginationLink previous href="#" />
                </PaginationItem>
                {[...Array(s.postsData.last_page)].map((data, key) => {
                  return(
                    <PaginationItem onClick={() => s.currentPage === key+1 ? null : [this.setState({currentPage: key+1}), this.getPosts()]}>
                      <PaginationLink href="#">
                        {key+1}
                      </PaginationLink>
                    </PaginationItem>
                  );
                })}
                <PaginationItem>
                  <PaginationLink next href="#" />
                </PaginationItem>
              </Pagination>
            </CardFooter>
          }
        </Card>

        <ConfirmationModal
          isOpen={s.postToDestroy}
          confirmationText={'نعم'}
          cancelText={'لا'}
          toggle={() => this.setState({postToDestroy: null})}
          onConfirm={() => this.destroy()}
          isLoading={s.destroyLoading}
        >
          هل حقا تريد حذف المقال؟
        </ConfirmationModal>
      </Container>
    );
  }
}

export default BlogPostsPage;
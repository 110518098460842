import React from "react";
import PropTypes from 'prop-types';
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export const StatisticsCard = (props) => {
  return(
    <div className="home-card-statistics bg-white mb-4 shadow-sm d-flex align-items-center justify-content-between">
      <h3 className="float-start ps-3 pt-2 pb-3">
        <span>{props.title}</span>
        <br />
        {props.isLoading ? <AiOutlineLoading3Quarters className="animation-spin" /> : props.value}
      </h3>
      <div className="me-3">
        {props.icon}
      </div>
    </div>
  );
}

StatisticsCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.element,
  isLoading: PropTypes.bool
}
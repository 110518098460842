import React, { Component } from "react";
import { Card, CardBody, Col, Container, FormGroup, Label, Row, Table } from "reactstrap";
import { AdminBodyTitle } from "../../components/Typography/Titles";
import { DefaultInput } from "../../components/Forms/Inputs/default";
import { DefaultSelect } from "../../components/Forms/Inputs/Select";
import { AiFillEdit, AiOutlineLoading3Quarters } from "react-icons/ai";
import { API_LINK } from "../../utils/constants";
import { DefaultButton } from "../../components/Buttons/default";
import { BottomLeftAlert } from "../../components/Alerts";
import { DefaultModal } from "../../components/Modals/Default";

class PackageCategoriesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categoriesData: null,
      categoriesLoading: true,

      languagesData: null,
      languagesLoading: true,

      countriesData: null,
      countriesLoading: true,

      addLoading: false,
      name: null,
      lang: null,
      country_id: null,

      alertText: null,

      updateLoading: null,
      categoryToEdit: null
    }
  }

  componentDidMount() {
    this.getLanguages();
  }

  getLanguages() {
    fetch(API_LINK+'/languages', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        languagesData: json.languages,
        languagesLoading: false
      }, () => {
        this.getCountries();
      });
    })
  }

  getCountries() {
    fetch(API_LINK+'/countries', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        countriesData: json.countries,
        countriesLoading: false
      }, () => {
        this.getCategories();
      });
    })
  }

  getCategories() {
    this.setState({categoriesLoading: true});
    
    fetch(API_LINK+'/package/categories', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        categoriesData: json.categories,
        categoriesLoading: false
      });
    })
  }

  storeCategory() {
    this.setState({addLoading: true});

    fetch(API_LINK+'/package/category/store', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        name: this.state.name,
        lang: this.state.lang,
        country_id: this.state.country_id
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          addLoading: false,
          name: '',
          lang: '',
          country_id: ''
        }, () => {
          this.getCategories();
        });
      } else {
        this.setState({
          addLoading: false,
          alertText: json.message
        });
      }
    })
  }

  updateCategory() {
    this.setState({updateLoading: true});

    fetch(API_LINK+'/package/category/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        id: this.state.categoryToEdit.id,
        name: this.state.categoryToEdit.name,
        lang: this.state.categoryToEdit.lang,
        country_id: this.state.categoryToEdit.country_id
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          updateLoading: false,
          categoryToEdit: null
        }, () => {
          this.getCategories();
        });
      } else {
        this.setState({
          alertText: json.message
        });
      }
    })
  }

  handleChange(name, event) {
    this.setState({
      ...this.state,
      [name]: event.target.value
    });
  }

  handleEditChange(name, event) {
    this.setState((prevState) => ({
      categoryToEdit: {
        ...prevState.categoryToEdit,
        [name]: event.target.value,
      },
    }));
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <AdminBodyTitle>تصنيفات العروض</AdminBodyTitle>
        <Row>
          <Col md="4">
            <Card>
            {s.languagesLoading ?
              <CardBody className="text-center">
                <AiOutlineLoading3Quarters className="animation-spin h2" />
              </CardBody>
            :
              <CardBody>
                <h4 className="fw-bold mb-4">إضافة تصنيف</h4>
                <FormGroup>
                  <Label>إسم التصنيف: </Label>
                  <DefaultInput 
                    value={s.name}
                    onChange={(event) => this.handleChange('name', event)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>الدولة: </Label>
                  <DefaultSelect value={s.country_id} onChange={(event) => this.handleChange('country_id', event)}>
                    <option value="">اختر الدولة</option>
                    {s.countriesData && s.countriesData.length > 0 &&
                      s.countriesData.map((data, key) => {
                        return(
                          <option value={data.id} key={key}>{data.name}</option>
                        );
                      })
                    }
                  </DefaultSelect>
                </FormGroup>
                <FormGroup>
                  <Label>اللغة: </Label>
                  <DefaultSelect value={s.lang} onChange={(event) => this.handleChange('lang', event)}>
                    <option value="">اختر اللغة</option>
                    {s.languagesData && s.languagesData.length > 0 &&
                      s.languagesData.map((data, key) => {
                        return(
                          <option value={data.short} key={key}>{data.name}</option>
                        );
                      })
                    }
                  </DefaultSelect>
                </FormGroup>
                <FormGroup>
                  <DefaultButton 
                    color={'dark'} 
                    block
                    isLoading={s.addLoading}
                    disabled={s.addLoading || !s.name || !s.lang}
                    onClick={() => this.storeCategory()}
                  >
                    حفظ
                  </DefaultButton>
                </FormGroup>
              </CardBody>
            }
            </Card>
          </Col>

          <Col md="8">
            <Card>
              <CardBody>
                <h4 className="fw-bold mb-4">كل التصنيفات</h4>
                <Table responsive className="table-bliz bg-light">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>الإسم</th>
                      <th>اللغة</th>
                      <th>الدولة</th>
                      <th>خيارات</th>
                    </tr>
                  </thead>
                  <tbody>
                    {s.categoriesLoading ?
                      <tr>
                        <td colSpan={'5'} align="center">
                          <AiOutlineLoading3Quarters className="animation-spin" />
                        </td>
                      </tr>
                    :
                      !s.categoriesData || s.categoriesData.length <= 0 ?
                        <tr>
                          <td colSpan={'5'} align="center">
                            لا توجد بيانات
                          </td>
                        </tr>
                      :
                        s.categoriesData.map((data, key) => {
                          return(
                            <tr key={key}>
                              <td>{ data.id }</td>
                              <td>{ data.name }</td>
                              <td>{ data.lang }</td>
                              <td>{ data.country?.name }</td>
                              <td>
                                <DefaultButton className="btn-xs" color={'success'} onClick={() => this.setState({categoryToEdit: data})}>
                                  <AiFillEdit />
                                </DefaultButton>
                              </td>
                            </tr>
                          );
                        })
                    }
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* alert */}
        {s.alertText &&
          <BottomLeftAlert color={'danger'}>{s.alertText}</BottomLeftAlert>
        }

        {/* edit modal */}
        <DefaultModal
          title={'تعديل التصنيف'} 
          isOpen={s.categoryToEdit} 
          toggle={() => this.setState({categoryToEdit: null})}
          onConfirm={() => this.updateCategory()}
          confirmationText={'حفظ'}
          cancelText={'غلق'}
          isLoading={s.updateLoading}
        >
          <FormGroup>
            <Label>إسم التصنيف: </Label>
            <DefaultInput 
              value={s.categoryToEdit?.name}
              onChange={(event) => this.handleEditChange('name', event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>الدولة: </Label>
            <DefaultSelect value={s.categoryToEdit?.country_id} onChange={(event) => this.handleEditChange('country_id', event)}>
              <option value="">اختر الدولة</option>
              {s.countriesData && s.countriesData.length > 0 &&
                s.countriesData.map((data, key) => {
                  return(
                    <option value={data.id} key={key}>{data.name}</option>
                  );
                })
              }
            </DefaultSelect>
          </FormGroup>
          <FormGroup>
            <Label>اللغة: </Label>
            <DefaultSelect value={s.categoryToEdit?.lang} onChange={(event) => this.handleEditChange('lang', event)}>
              {s.languagesData && s.languagesData.length > 0 &&
                s.languagesData.map((data, key) => {
                  return(
                    <option value={data.code} key={key}>{data.name} ({data.code})</option>
                  );
                })
              }
            </DefaultSelect>
          </FormGroup>
        </DefaultModal>
      </Container>
    );
  }
}

export default PackageCategoriesPage;
import React, { Component } from "react";
import { Card, CardBody, Col, Container, FormGroup, Label, Row, Table } from "reactstrap";
import { AdminBodyTitle } from "../../components/Typography/Titles";
import { DefaultInput } from "../../components/Forms/Inputs/default";
import { DefaultButton } from "../../components/Buttons/default";
import { DefaultSelect } from "../../components/Forms/Inputs/Select";
import { API_LINK } from "../../utils/constants";
import { AiFillEdit, AiOutlineLoading3Quarters } from "react-icons/ai";
import { BottomLeftAlert } from "../../components/Alerts";
import { DefaultModal } from "../../components/Modals/Default";

class TemplateSectionTypesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      typesData: null,
      typesLoading: true,

      addLoading: false,
      updateLoading: false,

      name: '',
      slug: '',
      lang: '',

      typeToEdit: null,

      languagesData: null,
      languagesLoading: true,

      alertText: null
    }
  }

  componentDidMount() {
    this.getLanguages();
  }

  getLanguages() {
    fetch(API_LINK+'/languages', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        languagesData: json.languages,
        languagesLoading: false
      }, () => {
        this.getTypes();
      });
    })
  }

  getTypes() {
    fetch(API_LINK+'/template/sections/types', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        typesData: json.types,
        typesLoading: false
      });
    })
  }

  store() {
    this.setState({addLoading: true});

    fetch(API_LINK+'/template/sections/type/store', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        name: this.state.name,
        slug: this.state.slug,
        lang: this.state.lang
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          addLoading: false,
          name: '',
          slug: '',
          lang: '',
        }, () => {
          this.getTypes();
        });
      } else {
        this.setState({
          alertText: json.message
        });
      }
    })
  }

  update() {
    this.setState({updateLoading: true});

    fetch(API_LINK+'/template/sections/type/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      },
      body: JSON.stringify({
        id: this.state.typeToEdit.id,
        name: this.state.typeToEdit.name,
        slug: this.state.typeToEdit.slug,
        lang: this.state.typeToEdit.lang
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          updateLoading: false,
          typeToEdit: null
        }, () => {
          this.getTypes();
        });
      } else {
        this.setState({
          alertText: json.message
        });
      }
    })
  }

  handleChange(name, event) {
    this.setState({
      ...this.state,
      [name]: event.target.value
    });
  }

  handleEditChange(name, event) {
    this.setState((prevState) => ({
      typeToEdit: {
        ...prevState.typeToEdit,
        [name]: event.target.value,
      },
    }));
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <AdminBodyTitle>أنواع الوحدات</AdminBodyTitle>
        <Row>
          <Col md="4">
            <Card>
              <CardBody>
                <h4 className="fw-bold mb-4">إضافة نوع</h4>
                <FormGroup>
                  <Label>إسم النوع: </Label>
                  <DefaultInput 
                    value={s.name}
                    onChange={(event) => this.handleChange('name', event)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>slug: </Label>
                  <DefaultInput 
                    value={s.slug}
                    onChange={(event) => this.handleChange('slug', event)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>اللغة: </Label>
                  <DefaultSelect value={s.lang} onChange={(event) => this.handleChange('lang', event)}>
                    <option value="">اختر اللغة</option>
                    {s.languagesData && s.languagesData.length > 0 &&
                      s.languagesData.map((data, key) => {
                        return(
                          <option value={data.short} key={key}>{data.name}</option>
                        );
                      })
                    }
                  </DefaultSelect>
                </FormGroup>
                <FormGroup>
                  <DefaultButton 
                    color={'dark'} 
                    block
                    isLoading={s.addLoading}
                    disabled={s.addLoading || !s.name || !s.slug || !s.lang}
                    onClick={() => this.store()}
                  >
                    حفظ
                  </DefaultButton>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
          <Col md="8">
            <Card>
              <CardBody>
                <h4 className="fw-bold mb-4">كل التصنيفات</h4>
                <Table responsive className="table-bliz bg-light">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>الإسم</th>
                      <th>Slug</th>
                      <th>اللغة</th>
                      <th>إعدادات</th>
                    </tr>
                  </thead>
                  <tbody>
                    {s.typesLoading ?
                      <tr>
                        <td colSpan={'5'} align="center">
                          <AiOutlineLoading3Quarters className="animation-spin" />
                        </td>
                      </tr>
                    :
                      !s.typesData || s.typesData.length <= 0 ?
                        <tr>
                          <td colSpan={'5'} align="center">
                            لا توجد بيانات
                          </td>
                        </tr>
                      :
                        s.typesData.map((data, key) => {
                          return(
                            <tr key={key}>
                              <td>{ data.id }</td>
                              <td>{ data.name }</td>
                              <td>{ data.slug }</td>
                              <td>{ data.lang }</td>
                              <td>
                                <DefaultButton className="btn-xs" color={'success'} onClick={() => this.setState({typeToEdit: data})}>
                                  <AiFillEdit />
                                </DefaultButton>
                              </td>
                            </tr>
                          );
                        })
                    }
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* alert */}
        {s.alertText &&
          <BottomLeftAlert color={'danger'}>{s.alertText}</BottomLeftAlert>
        }

        {/* edit modal */}
        <DefaultModal
          title={'تعديل التصنيف'} 
          isOpen={s.typeToEdit} 
          toggle={() => this.setState({typeToEdit: null})}
          onConfirm={() => this.update()}
          confirmationText={'حفظ'}
          cancelText={'غلق'}
          isLoading={s.updateLoading}
        >
          <FormGroup>
            <Label>إسم النوع: </Label>
            <DefaultInput 
              value={s.typeToEdit?.name}
              onChange={(event) => this.handleEditChange('name', event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>slug: </Label>
            <DefaultInput 
              value={s.typeToEdit?.slug}
              onChange={(event) => this.handleEditChange('slug', event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>اللغة: </Label>
            <DefaultSelect value={s.typeToEdit?.lang} onChange={(event) => this.handleEditChange('lang', event)}>
              {s.languagesData && s.languagesData.length > 0 &&
                s.languagesData.map((data, key) => {
                  return(
                    <option value={data.code} key={key}>{data.name} ({data.code})</option>
                  );
                })
              }
            </DefaultSelect>
          </FormGroup>
        </DefaultModal>
      </Container>
    );
  }
}

export default TemplateSectionTypesPage;